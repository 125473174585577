<template lang="pug">
  div.breadcrumbs
    ul.breadcrumbs__wrap
      li.breadcrumbs__item(v-for="(item, i) in breadcrumbsList" :key="item + i")
        router-link(:to="item.url" v-if="item.url").breadcrumbs__link {{ item.name }}
        span(v-else) {{ item.name }}
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    breadcrumbsList: {
      type: Array,
      default() {
        return [];
      },
    }
  },

}
</script>

<style scoped>

</style>