import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask';
const VueInputMask = require('vue-inputmask').default
import VueScrollmagic from 'vue-scrollmagic'
import MaskedInput from "vue-masked-input/src/MaskedInput";
import VueWaypoint from 'vue-waypoint';
import VueAnime from 'vue-animejs';
import VueNumber from 'vue-number-animation';
import VueParallaxJs from 'vue-parallax-js'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueParallaxJs)
import vueSmoothScroll from 'vue-smooth-scroll'
Vue.use(vueSmoothScroll)
import VModal from 'vue-js-modal'

Vue.use(VModal)
Vue.use(MaskedInput);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(VueScrollmagic, {
  vertical: true,
  globalSceneOptions: {},
  loglevel: 2,
  refreshInterval: 100
})
Vue.use(VueInputMask);
Vue.use(VueAnime);
Vue.use(VueNumber);
Vue.use(VueAxios, axios)


const YmapPlugin =  require('vue-yandex-maps');
Vue.use(YmapPlugin);
Vue.use(VueWaypoint);

import jQuery from 'jquery';

// const settings = {
//   apiKey: '',
//   lang: 'ru_RU',
//   coordorder: 'latlong',
//   version: '2.1'
// }

Vue.use(VueAwesomeSwiper, {});
//Vue.use(YmapPlugin)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
