<template lang="pug">
  .circle
    svg(width='89' height='89' viewbox='0 0 89 89' fill='none' xmlns='http://www.w3.org/2000/svg')
      g.circle-group
        path(d='M74.704 50.2325L78.5261 48.6739L78.7893 46.7882L75.2536 46.2947L75.4762 44.7002L85.1821 46.0549L84.6402 49.9372C84.5189 50.8061 84.1113 51.4984 83.4174 52.0141C82.7314 52.5404 81.9539 52.7428 81.085 52.6216C80.4657 52.5351 79.9223 52.2755 79.455 51.8428C78.9956 51.4205 78.6904 50.9067 78.5393 50.3014L74.4601 51.9796L74.704 50.2325ZM83.462 47.4404L80.2175 46.9875L79.8982 49.2754C79.8388 49.7006 79.9456 50.083 80.2186 50.4227C80.4916 50.7623 80.8546 50.9638 81.3076 51.027C81.7605 51.0902 82.1648 50.9959 82.5203 50.7439C82.8759 50.492 83.0834 50.1534 83.1427 49.7282L83.462 47.4404Z' fill='#3E4DDB')
        path(d='M74.8269 55.5122L73.1662 59.5219L71.7434 58.9326L74.0202 53.4355L83.0743 57.1855L80.8243 62.618L79.4015 62.0287L81.0354 58.0837L78.6814 57.1087L77.1813 60.7303L75.7715 60.1464L77.2715 56.5248L74.8269 55.5122Z' fill='#3E4DDB')
        path(d='M67.527 64.9271L69.5155 65.57L72.0308 62.2415L70.8696 60.5041L71.9247 59.1079L77.5992 67.8534L76.4344 69.3948L66.4804 66.3121L67.527 64.9271ZM72.9164 63.5775L71.0425 66.0571L75.509 67.4846L72.9164 63.5775Z' fill='#3E4DDB')
        path(d='M65.5257 68.9793L62.4048 71.4653L61.4453 70.2608L65.8254 66.7716L71.9315 74.4368L70.6723 75.4399L65.5257 68.9793Z' fill='#3E4DDB')
        path(d='M56.1461 74.6644L52.044 76.0817L51.5411 74.6261L57.1649 72.6831L60.3652 81.9458L54.8075 83.866L54.3046 82.4104L58.3405 81.016L57.5085 78.6077L53.8034 79.8878L53.3051 78.4455L57.0101 77.1654L56.1461 74.6644Z' fill='#3E4DDB')
        path(d='M47.3113 75.013C48.25 74.9259 49.069 75.0655 49.7685 75.4317C50.4588 75.7989 50.9685 76.3421 51.2977 77.0614L49.9927 77.998C49.5006 76.9376 48.6411 76.4643 47.4144 76.5782C46.8103 76.6343 46.3704 76.7923 46.0946 77.0522C45.8103 77.3223 45.6859 77.6479 45.7212 78.0289C45.7583 78.4285 45.949 78.7154 46.2932 78.8897C46.6383 79.0733 47.2361 79.2521 48.0865 79.4263C48.5641 79.5226 48.9526 79.6177 49.2519 79.7118C49.5512 79.8059 49.8734 79.9447 50.2185 80.1282C50.5552 80.322 50.8176 80.5741 51.0058 80.8847C51.194 81.1953 51.3083 81.569 51.3489 82.0058C51.4291 82.8701 51.1858 83.5816 50.619 84.1404C50.0531 84.7085 49.338 85.0326 48.4737 85.1128C47.6931 85.1853 46.9923 85.0582 46.3715 84.7315C45.7506 84.4048 45.2511 83.9216 44.8731 83.2818L46.1528 82.3756C46.6462 83.2484 47.3716 83.6404 48.3288 83.5515C48.7935 83.5084 49.1548 83.3624 49.4129 83.1135C49.6719 82.8738 49.7841 82.5682 49.7496 82.1964C49.716 81.834 49.5499 81.5588 49.2512 81.371C48.9441 81.1932 48.4081 81.0227 47.643 80.8594C47.2601 80.7731 46.982 80.7052 46.8086 80.6556C46.6269 80.6163 46.3766 80.5458 46.0579 80.4441C45.7298 80.3433 45.4808 80.2352 45.3107 80.1198C45.1407 80.0043 44.9583 79.8572 44.7636 79.6785C44.5596 79.5005 44.4088 79.2896 44.3112 79.0456C44.2135 78.8015 44.15 78.5215 44.1207 78.2056C44.0388 77.3227 44.2943 76.5913 44.8872 76.0113C45.4717 75.4415 46.2798 75.1088 47.3113 75.013Z' fill='#3E4DDB')
        path(d='M34.7856 84.2018L35.0101 82.6783L37.7387 83.0805L38.9432 74.9088L40.536 75.1436L39.3314 83.3153L42.0738 83.7195L41.8492 85.243L34.7856 84.2018Z' fill='#3E4DDB')
        path(d='M29.8368 71.9225L29.6919 74.0072L33.5282 75.6468L34.9348 74.1012L36.544 74.7889L29.4228 82.403L27.6462 81.6437L28.2405 71.2402L29.8368 71.9225ZM32.4448 76.8281L29.5869 75.6067L29.2773 80.2854L32.4448 76.8281Z' fill='#3E4DDB')
        path(d='M16.6172 74.5809L17.559 73.3625L19.7411 75.0493L24.7929 68.5143L26.0667 69.499L21.0148 76.034L23.208 77.7294L22.2661 78.9477L16.6172 74.5809Z' fill='#3E4DDB')
        path(d='M19.983 66.1009L17.2175 62.7561L18.4044 61.7748L22.1958 66.3604L14.643 72.6051L10.8962 68.0734L12.0831 67.0921L14.804 70.383L16.7677 68.7593L14.2698 65.7382L15.4459 64.7659L17.9438 67.787L19.983 66.1009Z' fill='#3E4DDB')
        path(d='M14.304 52.6569L11.5713 55.7505L12.1726 57.557L15.5599 56.4294L16.0684 57.957L6.7701 61.0524L5.53195 57.333C5.25484 56.5006 5.31271 55.6993 5.70556 54.9291C6.0866 54.153 6.69333 53.6264 7.52575 53.3493C8.11907 53.1518 8.72117 53.1432 9.33205 53.3235C9.93113 53.4979 10.4328 53.8227 10.8369 54.2981L13.7469 50.9832L14.304 52.6569ZM7.69617 59.0472L10.8045 58.0125L10.0748 55.8207C9.93923 55.4134 9.67372 55.1181 9.27829 54.935C8.88287 54.7518 8.4682 54.7325 8.03428 54.8769C7.60036 55.0214 7.28002 55.2854 7.07327 55.669C6.86651 56.0526 6.83094 56.4481 6.96655 56.8555L7.69617 59.0472Z' fill='#3E4DDB')
        path(d='M11.8567 48.041L11.5556 43.7115L13.0918 43.6046L13.5047 49.5403L3.72829 50.2203L3.32031 44.3544L4.8566 44.2476L5.15287 48.5073L7.69473 48.3305L7.42275 44.4199L8.94507 44.3141L9.21706 48.2246L11.8567 48.041Z' fill='#3E4DDB')
        path(d='M4.09552 36.8827C4.36244 35.5655 5.0459 34.566 6.14588 33.8842C7.24772 33.1933 8.47555 32.985 9.82936 33.2593C11.174 33.5318 12.2239 34.2016 12.979 35.2689C13.7268 36.3251 13.9672 37.5118 13.7003 38.829L12.9357 42.6023L3.33089 40.656L4.09552 36.8827ZM12.191 38.5232C12.3745 37.6176 12.2127 36.8182 11.7056 36.125C11.2003 35.4227 10.4721 34.9751 9.52073 34.7823C8.56025 34.5877 7.71519 34.7164 6.98553 35.1685C6.24858 35.6096 5.78835 36.283 5.60484 37.1886L5.15996 39.3839L11.7461 40.7186L12.191 38.5232Z' fill='#3E4DDB')
        path(d='M14.5511 30.8853L16.5198 27.0175L17.8922 27.716L15.1932 33.0187L6.45946 28.5733L9.12669 23.333L10.4991 24.0316L8.56222 27.837L10.833 28.9928L12.6112 25.4993L13.9711 26.1915L12.193 29.685L14.5511 30.8853Z' fill='#3E4DDB')
        path(d='M19.9416 25.0063L10.3449 21.0672L11.5128 19.7453L19.1228 22.9939L14.9703 15.8318L16.129 14.5203L21.2208 23.5584L19.9416 25.0063Z' fill='#3E4DDB')
        path(d='M24.7471 18.2321L28.3389 15.796L29.2033 17.0705L24.2791 20.4103L18.7782 12.2999L23.6444 8.9993L24.5089 10.2738L20.975 12.6706L22.4053 14.7794L25.6495 12.579L26.5061 13.8419L23.2619 16.0423L24.7471 18.2321Z' fill='#3E4DDB')
        path(d='M31.0473 14.5448L34.767 13.1012L35.3242 14.5368L30.1036 16.563L26.5577 7.42695L28.0587 6.84443L31.0473 14.5448Z' fill='#3E4DDB')
        path(d='M44.5837 11.9098C43.6903 12.9693 42.5368 13.5592 41.1232 13.6795C39.7096 13.7997 38.4684 13.4135 37.3996 12.5208C36.3393 11.6181 35.749 10.46 35.6288 9.04642C35.5086 7.63286 35.8952 6.39631 36.7886 5.33679C37.6905 4.26717 38.8483 3.67225 40.2618 3.55202C41.6754 3.43179 42.9123 3.82304 43.9726 4.72576C45.0415 5.61839 45.636 6.77148 45.7562 8.18505C45.8765 9.59861 45.4856 10.8402 44.5837 11.9098ZM38.437 11.3367C39.1627 11.94 40.0138 12.2001 40.9903 12.1171C41.9668 12.034 42.7571 11.6343 43.3612 10.9179C43.9738 10.1913 44.2374 9.32587 44.152 8.32149C44.0666 7.31712 43.661 6.51326 42.9353 5.90993C42.218 5.29651 41.3712 5.03132 40.3947 5.11438C39.4182 5.19743 38.6237 5.60222 38.011 6.32876C37.4069 7.0452 37.1476 7.90561 37.233 8.90998C37.3184 9.91435 37.7198 10.7233 38.437 11.3367Z' fill='#3E4DDB')
        path(d='M52.8428 4.46088C53.7462 4.65475 54.4345 5.11745 54.9075 5.84898C55.3897 6.58248 55.5348 7.39637 55.3429 8.29068C55.153 9.17586 54.6867 9.85856 53.9441 10.3388C53.2125 10.8118 52.395 10.9514 51.4916 10.7575L49.5342 10.3375L48.8292 13.6227L47.255 13.2849L49.3112 3.70304L52.8428 4.46088ZM51.8088 9.27919C52.2743 9.37907 52.6908 9.30618 53.0585 9.06053C53.4282 8.80576 53.665 8.43654 53.7688 7.95288C53.8725 7.46923 53.8071 7.03995 53.5726 6.66506C53.34 6.28104 52.991 6.0391 52.5256 5.93923L50.5681 5.51919L49.8514 8.85915L51.8088 9.27919Z' fill='#3E4DDB')
        path(d='M67.7605 10.7473L62.9953 19.3108L61.5885 18.5279L64.9854 12.4234L59.9834 15.2796L59.8121 15.1843L59.6094 9.41588L56.2057 15.5326L54.7988 14.7498L59.564 6.18633L61.081 7.03045L61.2797 12.7485L66.2558 9.90998L67.7605 10.7473Z' fill='#3E4DDB')
        path(d='M66.2749 20.3759L69.3326 23.4558L68.2397 24.5408L64.0477 20.3183L71.0025 13.4139L75.1452 17.5867L74.0523 18.6717L71.0439 15.6414L69.2357 17.4366L71.9974 20.2185L70.9145 21.2936L68.1527 18.5117L66.2749 20.3759Z' fill='#3E4DDB')
        path(d='M80.3576 25.3047L81.1714 26.6939L72.7154 31.6473L72.0785 30.5601L75.4813 23.2125L69.7434 26.5737L68.9296 25.1845L77.3856 20.2311L78.0224 21.3183L74.6196 28.6659L80.3576 25.3047Z' fill='#3E4DDB')
        path(d='M84.4214 36.5777L82.9381 36.9917L82.1965 34.3353L74.2407 36.5562L73.8078 35.0055L81.7637 32.7846L81.0184 30.1147L82.5016 29.7006L84.4214 36.5777Z' fill='#3E4DDB')
      path(d='M49.4451 36.6902H51.2308V49.708C51.2308 51.6607 50.5634 53.1975 49.2287 54.3185C47.894 55.4394 46.2978 55.9999 44.44 55.9999C41.2656 55.9999 39.1283 54.897 38.0281 52.6912L39.5972 51.7963C40.4089 53.4416 42.0231 54.2642 44.44 54.2642C45.9912 54.2642 47.2086 53.8484 48.0924 53.0167C48.9942 52.2031 49.4451 51.1002 49.4451 49.708V47.5926C48.2006 49.5814 46.3789 50.5758 43.9801 50.5758C42.0502 50.5758 40.3999 49.8888 39.0291 48.5147C37.6764 47.1406 37 45.4591 37 43.4703C37 41.4815 37.6764 39.8 39.0291 38.4259C40.3999 37.0518 42.0502 36.3647 43.9801 36.3647C46.3789 36.3647 48.2006 37.3592 49.4451 39.348V36.6902ZM40.3277 47.2943C41.3558 48.3248 42.6184 48.8401 44.1154 48.8401C45.6124 48.8401 46.8749 48.3248 47.903 47.2943C48.9311 46.2456 49.4451 44.971 49.4451 43.4703C49.4451 41.9696 48.9311 40.704 47.903 39.6734C46.8749 38.6248 45.6124 38.1005 44.1154 38.1005C42.6184 38.1005 41.3558 38.6248 40.3277 39.6734C39.2996 40.704 38.7856 41.9696 38.7856 43.4703C38.7856 44.971 39.2996 46.2456 40.3277 47.2943Z' fill='white')
      ellipse(cx='41.8859' cy='33.252' rx='1.24868' ry='1.25171' fill='#FFB470')
      ellipse(cx='46.8805' cy='33.2517' rx='1.24868' ry='1.25171' fill='#FFB470')
</template>

<script>
export default {
name: "GradeCircle"
}
</script>

<style scoped>

</style>