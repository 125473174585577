<template lang="pug">
  div.input-block(:class="{error: error}")
    label(:for="id") {{label}}
    input(
      @focus="$emit('focus')"
      type="tel"
      :id="id"
      :placeholder="placeholder"
      @input="$emit('inputChange', $event.target.value)"

      )
    span.input-block__error(v-if="messageEmpty") {{messageEmpty}}
</template>

<script>
export default {
name: "InputTel",
  props: [
      'id', 'placeholder', 'label', 'messageEmpty', 'error'
  ]
}
</script>

<style scoped>

</style>