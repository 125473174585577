<template lang="pug">
  .add-file
    input(type="file" :id="inputId")
    label(:for="inputId") Прикрепить файл
</template>

<script>
export default {
name: "addFile",
  props: [
      'inputId'
  ]
}
</script>

<style scoped lang="sass">
.add-file
  padding-bottom: 35px
input
  display: none
label
  display: inline-block
  position: relative
  margin-left: 27px
  border-bottom: 1px solid #232323
  &:before
    content: ""
    position: absolute
    width: 16px
    height: 20px
    left: -27px
    top: 0
    background:  url("../assets/imgs/clip.svg") no-repeat center
    background-size: contain


</style>