<template lang="pug">
    div
        intro-block(
            url='intro_img.png'
            title="Проекты Grade"
            :link="prevRoute"
        )

        section.projects
            .container
                Breadcrumbs(:breadcrumbsList="breadcrumbs")

                .projects__top
                    h2
                        span Проекты, созданные
                        span компанией GRADE
                    .projects__filter-wrap
                        ul.projects__filter(v-if="getAllProjects.length")

                            li(v-for="item in getAllProjects"
                                v-if="item.category.name !== ''"
                                :id="item.category.id"
                                @click="chooseProject(item.category.id)"
                                :class="{active : id === item.category.id}"
                            )
                                button {{item.category.name}}

                        //.projects__filter--mobile
                          swiper(ref="swiperPortfolio" :options="swiperOptionsPortfolio").projects__filter
                            swiper-slide(v-for="item in tabsList")
                              div(:id="item.id"
                                //:class="{active : id === item.id}" @click="chooseProject(item.id)")
                                button {{item.text}}

                        //.projects__filter--mobile
                        //    slick(ref="slick" :options="slickOptions" class="projects__filter")
                        //        div(v-for="item in getCategoriesData" :id="item.id" :class="{active : id === item.id}" @click="chooseProject(item.id)")
                        //            button {{item.title}}

                transition-group(name="slide-in" tag="div" class="projects__list" :style="{ '--total': filterProjectList.length }" v-for="item in getAllProjects[id-1]")
                    a.projects__item(
                        v-for="(elem, i) in filterProjectList"
                        :key="elem.id"
                        @click="goToProject(elem.slug)"
                        :data-project="elem.id"
                        :style="{'--i': i}"
                        class="projects__item--big"
                        :class="{'projects__item--big' : i % 6 === 0 || ((i - 4) % 6 === 0 && (i - 4)/6 >= 0), 'gray': gray && hoverItem !== i}"
                    )
                        // для     goToProject
                        // getAllProjects[id-1].category.projects.filter(item => item.slug !== elem.slug)
                        // Допoлнитель        ные классы 'projects__item--medium' : ((i - 2 ) % 6 === 0 && (i - 2)/6 >= 0) || ((i - 3 ) % 6 === 0 && (i - 3)/6 >= 0), 'projects__item--small' : ((i - 1 ) % 6 === 0 && (i - 1)/6 >= 0) || ((i - 5 ) % 6 === 0 && (i - 5)/6 >= 0)
                        div(v-on:mouseover = "overHandle(i)" v-on:mouseout = "outHandle(i)")
                            .projects__item-img
                                img(:src="getRootUrl + elem.desktop_picture" alt="" )
                            p {{elem.name}}

                button.projects-btn(@click="showMoreProjects" v-if="buttonIsVisible && getAllProjects[id-1].category.projects.length > 6") Загрузить еще


</template>

<script>
import Slick from "vue-slick";
import Button from "@/components/main/Button";
//import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import {mapGetters, mapActions, mapMutations} from "vuex";
import 'swiper/css/swiper.css';
import IntroBlock from "@/components/portfolio/IntroBlock";
import Breadcrumbs from "@/components/main/Breadcrumbs";

export default {
    name: "Portfolio",
    components: {Breadcrumbs, IntroBlock, Button, Slick},
    data() {
        return {
            breadcrumbs: [],
            slickOptions: {
                respondTo: 'slider',
                infinite: false,
                slidesToShow: 'auto',
                slidesToScroll: 1,
                variableWidth: true,
                arrows: false,
                touchMove: true
                // focusOnSelect: false
                //centerMode: true
                // Any other options that can be got from plugin documentation
            },
            id: 1,
            elems: 6,
            buttonIsVisible: false,
            gray: false,
            hoverItem: null,
            buttonIsVisibleEnd: true,
            swiperOptionsPortfolio: {
                loop: true,
                slidesPerView: 3,
                centeredSlides: true,
                spaceBetween: 10,
                speed: 900,
                direction: 'horizontal',
                freeMode: true
            },
            prevRoute: null,
            recommendedProjects: [],
        }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
      })
    },
    computed: {
        ...mapGetters(['getCategoriesData', 'getAllProjects', 'getRootUrl', 'getProjectsDataById']),
        // swiper() {
        //   return this.$refs.swiperPortfolio.$swiper
        // },
        filterProjectList() {
            let projects = [];

            projects = this.$store.getters.getProjectsDataById(this.id);

            let count = this.getCategoriesData.find(item => item.id == this.id).count;
            if(this.buttonIsVisibleEnd && projects.length) {
                count < projects.length ? this.buttonIsVisible = true : this.buttonIsVisible = false;
            }
            return projects.slice(0, this.elems);

        }
    },
    methods: {
        ...mapMutations(['setActivePage']),
        goToProject(link) {
            this.$router.push({ name: 'OneProject', params: { id: link}})
        },
        overHandle(i) {
            this.gray = true;
            this.hoverItem = i;
        },
        outHandle(i) {
            this.gray = false;
        },
        chooseProject(id) {
            this.elems = 6;
            this.buttonIsVisible = false;
            this.id = id;
            let count = this.getCategoriesData.find(item => item.id == id).count;
            count < 6 ? this.buttonIsVisible = false : this.buttonIsVisible = true;

        },
        showMoreProjects() {
            this.elems += 3;
            if (this.elems >= this.$store.getters.getProjectsDataById(this.id).length) {
                this.buttonIsVisible = false;
                this.buttonIsVisibleEnd = false;
            }
        }
    },
    mounted() {
        this.setActivePage('Портфолио (объекты)');

        // setTimeout(() => {
        //     const tlVueGsap = new TimelineMax()
        //         .from('.projects__top h2 span:first-child', 0.7, {
        //             y: 100,
        //             rotation: "8deg",
        //             delay: 0.8,
        //             opacity: 0,
        //             ease: "Power2.easeOut"
        //         })
        //         .from('.projects__top h2 span:last-child', 0.7, {
        //             y: 100,
        //             rotation: "8deg",
        //             opacity: 0,
        //             delay: -0.5,
        //             ease: "Power2.easeOut"
        //         })
        //         .from('.projects__filter', 0.5, {y: 100, opacity: 0, delay: -0.2, ease: "Power2.easeOut"})
        //
        //     const scene1 = this.$scrollmagic
        //         .scene({
        //             triggerElement: ".projects",
        //             triggerHook: 0.8,
        //             reverse: false
        //         })
        //         .setTween(tlVueGsap)
        //     this.$scrollmagic.addScene(scene1);
        // }, 200)
    },
    created() {
        this.breadcrumbs = [
            {
                name: 'Главная',
                url: '/'
            },
            {
                name: 'Проекты',
            }
        ]
    }
}
</script>

<style scoped>

</style>
