<template lang="pug">
  .service-item__text-wrap
    h3
      span {{ServiceInfo.description[0].value}}
    ul
      li(v-for="(item, i) in ServiceInfo.description.slice(1)") {{ item.value }}
        button(type="button"
          v-if="item.children.length > 0"
          @click="toggleSubmenu(i)"
          :class="{isOpen : isOpen && i === activeItem}") Подробнее
        ul(v-if="item.children").service-item__submenu(:class="{isOpen : isOpen && i === activeItem}")
          li(v-for="elem in item.children") {{ elem.value }}


</template>

<script>
export default {
name: "ServiceItemList",
  props:
      {
        ServiceInfo: {
          type: Object,
          default() {
            return {};
          },
        }
      },
  data() {
    return {
      isOpen: false,
      activeItem: null
    }
  },
  methods: {
    toggleSubmenu(i) {
        this.isOpen = true;

        if (this.activeItem === i) {
          this.activeItem = null
          this.isOpen = false
        } else {
          this.activeItem = i;
        }
      }
  }
}
</script>

<style scoped>

</style>