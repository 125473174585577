<template lang="pug">
  div.input-block.input-checkbox(:class="{error: error}")

    input(type="checkbox" :id="id" checked @change="$emit('changeCheckbox', $event.target.checked)")
    label(:for="id") Я согласен на обработку персональных данных и с
        a(:href="getRootUrl + '/' + getPolicy" target="_blank")  политикой конфиденциальности
    span.input-block__error(v-if="messageEmpty") {{messageEmpty}}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Checkbox",
  props: [
      'id', 'messageEmpty', 'error'
  ],
  computed: {
    ...mapGetters(['getPolicy', 'getRootUrl'])
  }
}
</script>

<style scoped>

</style>