<template lang="pug">
    form.form(@submit.prevent="sendData" :class="{success : success}")
        .form-success(v-if="success")
            p.form-success__title Заявка оформлена
            p.form-success__text Ожидайте, в ближайшее время с вами свяжется наш специалист.
        .form-wrap(v-if="!success")
            .form__row
                input-block(
                    @focus="$v.form.userName.$reset()"
                    id="offer-name"
                    placeholder="Введите имя"
                    label="Имя"
                    @inputChange="changeInput($event, 'userName')"
                    :error="$v.form.userName.$error"
                    :messageEmpty="($v.form.userName.$dirty && !$v.form.userName.required) ? 'заполните поле' : null"
                )
            .form__row
                input-block(
                    @focus="$v.form.email.$reset()"
                    id="offer-email"
                    placeholder="Введите email"
                    label="E-mail"
                    @inputChange="changeInput($event, 'email')"
                    :error="$v.form.email.$error"
                    :messageEmpty="($v.form.email.$dirty && !$v.form.email.required) ? 'заполните поле' : null"
                    :message2="($v.form.email.$dirty && !$v.form.email.email) ? 'некорректный адрес электронной почты' : null"
                )
            .form__row
                div.input-block(:class="{error: $v.form.tel.$error}")
                    label(for="offer-tel") Телефон
                    input(type="tel"
                        id="offer-tel"
                        v-mask="'+7 (999) 999-99-99'"
                        focus="$v.form.tel.$reset()"
                        v-model="form.tel"
                        placeholder="+7 (___) ___-__-__"
                    )

                    span.input-block__error(v-if="($v.form.tel.$dirty && !$v.form.tel.required) ? 'заполните поле' : null") заполните поле
                    span.input-block__error(v-if="form.tel.replace(/[^+\\d]/g, '').length < 12 && form.tel.replace(/[^+\\d]/g, '').length > 0") заполните номер полностью
            .form__row
                input-block(
                    id="offer-comment"
                    placeholder="Введите текст"
                    label="Комментарий"
                    @inputChange="changeInput($event, 'comment')"
                )

            button(type="submit").button.button--medium Отправить

            .form__row
                checkbox(
                    id="offer-policy"
                    @changeCheckbox="changeCheckbox($event)"
                    :error="$v.form.checkbox.$error"
                )


</template>

<script>
import InputBlock from "@/components/InputBlock";
import InputTel from "@/components/InputTel";
import Button from "@/components/main/Button";
import Checkbox from "@/components/Checkbox";
import AddFile from "@/components/addFile";
import {email, required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from "vuex";

function telValidator(value) {
    return !(value.replace(/[^+\d]/g, '').length < 12)
}

export default {
    name: "OfferFormBlock",
    components: {
        AddFile, Checkbox, Button, InputTel, InputBlock
    },
    data() {
        return {
            name: "",
            success: false,
            form: {
                userName: "",
                comment: "",
                email: "",
                tel: "",
                checkbox: true
            },
          myForm: new FormData()
        }
    },

    methods: {
        ...mapActions(["sendForm"]),
        changeCheckbox(value) {
            this.form.checkbox = value;
        },
        changeInput(value, input) {
            this.form[input] = value;
        },
        sendData() {
            this.$v.$touch();

          if (!this.$v.$invalid) {
            this.myForm.append('userName', this.form.userName)
            this.myForm.append('email', this.form.email)
            this.myForm.append('tel', this.form.tel)
            this.myForm.append('comment', this.form.comment)
            this.myForm.append('url', this.getUrl)

            this.sendForm(this.myForm);
            this.success = true;
            setTimeout(() => {
              this.success = false
            }, 2000)
          }
        }
    },
    validations: {
        form: {
            userName: {
                required
            },
            email: {
                required,
                email
            },
            tel: {
                required,
                telValidator
            },
            checkbox: {
                checked(val) {
                    return val
                }
            }
        }
    },
    computed: {
      ...mapGetters(['getUrl'])
    }
}
</script>

<style scoped>

</style>
