<template lang="pug">
  div
    .swiper-mobile#mobile
      swiper(ref="mySwiper2" :options="swiperOptions")
        swiper-slide(v-for="(img, id) in photos" :key="id")
          .projects__img-wrap
            img(:src="url + img.file_path" alt="")
            img.projects__img-mobile(:src="url + img.file_path" alt="")

      .swiper-pagination-mobile(slot="pagination")

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
name: "SwiperMobile",
  components: {
    Swiper,
    SwiperSlide },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 10,
        direction: 'horizontal',
        loop: true,
        speed: 900,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 3,
        pagination: {
          el: '.swiper-pagination-mobile',
          type: 'bullets',
        }
      }
    }
  },
  props: {
    photos: Array,
    url: String
  },
  computed: {
    swiperMobile() {
          return this.$refs.mySwiper2.$swiper
    }
  },
  mounted() {
    this.swiperMobile.init();
  }
}
</script>

<style scoped>

</style>