<template lang="pug">
  ul.service-gallery
    li.service-gallery__item(v-for="(item, i) in List" :key="'item' + i")
      .service-gallery__img(:style="'background-image: url(' + require(`@/assets/imgs/${item}`) + ')'")
</template>

<script>
export default {
name: "ServiceGallery",
  props: {
    List: {
      type: Array,
      default() {
        return [];
      },
    },
  },
}
</script>

<style scoped lang="sass">
.service-gallery
  li
    padding-left: 0
    &:before
      display: none
</style>