<template lang="pug">
  .contacts__map
    #map
      yandex-map(
        :coords="mapCenter"
        :zoom="mapZoom"
        :cluster-options="{1: {clusterDisableClickZoom: true}}"
        :behaviors="['dblClickZoom', 'drag', 'multiTouch']"
        :controls="['zoomControl']"
      )

        ymap-marker(
          marker-type="placemark"
          marker-id="mainMap"
          :coords="[59.914740, 30.277500]"
          :icon='markerIcon'
          cluster-name="1")
          //hint-content="Hint content 1"
          //:balloon="{header: 'header', body: 'body', footer: 'footer'}"
</template>

<script>

import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {mapActions, mapGetters, mapMutations} from "vuex";
import store from "../store";

export default {
name: "ContactsMap",
  components: {yandexMap, ymapMarker},
  data() {
    return {
      settings: {
        apiKey: ''
      },
      screenWidth: null,
      // Центр и маркер карты в зависимости от ширины экрана
      mapCenter: document.documentElement.offsetWidth > 1023 ? [59.914740, 30.277500] : [59.915193, 30.265279],
      mapZoom: document.documentElement.offsetWidth > 1023 ? 12 : 13,
      placemarks: [
        {
          properties: {}, // define properties here
          options: {}, // define options here
          clusterName: "1",
          callbacks: { click: function() {} }
        }
      ],
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: require('@/assets/imgs/map-marker.svg'),
        imageSize: document.documentElement.offsetWidth > 1023 ? [62, 62] : [36, 36],
        imageOffset: [-30, -20],
        contentOffset: [0, 0],
      },
    }
  },
  computed: {
    ...mapGetters(['getContactInfo']),
  },
  mounted() {
    // Создание ширины экрана
    this.screenWidth = document.documentElement.offsetWidth;
    // Обновление центра и зума карты
    window.addEventListener('resize', () => {
      this.screenWidth = document.documentElement.offsetWidth;
      this.mapZoom = this.screenWidth > 1023 ? 12 : 13;
      this.mapCenter = this.screenWidth > 1023 ? [59.91487206417389,30.276683499999976] : [59.915193, 30.265279]
    })
  },
  methods: {
    ...mapActions(['requestContactsInfo'])
  }
}
</script>

<style scoped>

</style>