<template lang="pug">
  .licence
    .container.pl(id="licence" v-if="getLicenceDescription")
      .license__wrap
        .license__info
          h2
            span {{ getLicenceDescription.title.split(' ')[0] }}
            span {{ getLicenceDescription.title.split(' ')[1] }}
          .license__text-wrap
            .license__text
              p {{ getLicenceDescription.text }}

        .license__slider
          .license__slider-wrap
            swiper(ref="mySwiperLicense" :options="swiperOptions")
              swiper-slide(v-for="(img, id) in getLicence" :key="id")
                img(:src="getRootUrl + img")
              //swiper-slide
              //  img(src="@/assets/imgs/license_2.png")
              //swiper-slide
              //  img(src="@/assets/imgs/license_3.png")
        .swiper-pagination-license(slot="pagination")
        .swiper-pagination-mobile-license(slot="pagination")

        .swiper__navigation
          .swiper-button-prev.license-swiper-btn-prev(slot="button-prev")
          .swiper-button-next.license-swiper-btn-next(slot="button-next")
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import {mapGetters, mapActions} from "vuex";
export default {
name: "License",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        direction: 'horizontal',
        speed: 900,
        pagination: {
          el: '.swiper-pagination-license',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.license-swiper-btn-next',
          prevEl: '.license-swiper-btn-prev'
        },
        breakpoints: {
          // when window width is >= 320px
          1280: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          320: {
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 3,
            spaceBetween: 10,
            pagination: {
              el: '.swiper-pagination-mobile-license',
              type: 'bullets'
            },
          }
        }

      }
    }
  },
  computed: {
    ...mapGetters(['getLicence', 'getRootUrl', 'getLicenceDescription']),

    swiper() {
      return this.$refs.mySwiperLicense.$swiper
    }
  },

  created() {
    this.requestLicence();
  },

  mounted() {
    this.swiper.init();
    let self = this;
    // setTimeout(() => {
    //   const tlVueGsap = new TimelineMax()
    //       .from('.license__info h2 span:first-child', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
    //       .from('.license__info h2 span:last-child', 1, {
    //         y: 100,
    //         rotation: "8deg",
    //         opacity: 0,
    //         delay: -0.5,
    //         ease: "Power2.easeOut"
    //       })
    //       .from('.license__slider-wrap .swiper-container', 1, {y: 800, opacity: 0, delay: -0.5, ease: "Power2.easeOut"})
    //   const scene4 = this.$scrollmagic
    //       .scene({
    //         triggerElement: ".licence",
    //         triggerHook: 0.8,
    //       })
    //       .setTween(tlVueGsap)
    //
    //   this.$scrollmagic.addScene(scene4);
    //
    // }, 200)
  },

  methods: {
    ...mapActions(['requestLicence'])
  }
}
</script>

<style scoped>

</style>
