<template lang="pug">
  section.redevelop
    .container.pl
      .redevelop__wrap
        .redevelop__left-side
          h2.redevelop__title
            span Инженерное
            span переоснащение
          .redevelop__text
            p Инженерные системы Grade это особая часть работ по реновации. Работа по инженерным сетям стояла у истоков компании. Теперь многолетний опыт позволяет реализовывать переоснащение инженерными сетями промышленные объекты, коммерческие, работать с жилыми домами города Санкт-Петербурга в Фонде Капитального ремонта.

          Statistics(:statistics-list="statisticsList" :trigger='play')

          RoundButton(:text="redevelopInfo.btnText" link="/about")

        .redevelop__right-side
          .redevelop__img
            img(src="@/assets/imgs/machine.jpg" alt="")
          .redevelop__info
            p Инженерные системы Grade это компетенции по разделам: ВК, ОВ, СС, ЭГ, ЭС, ЭМ, ЭО, ЭН/
          //Statistics(:statistics-list="statisticsListTwo" :trigger='play1')




</template>

<script>
import Button from "@/components/main/Button";
import RoundButton from "@/components/main/RoundButton";
import Statistics from "@/components/Statistics";
import {mapState} from "vuex";

// import * as ScrollMagic from "scrollmagic";
// import {gsap} from "gsap";
// import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
//
// ScrollMagicPluginGsap(ScrollMagic, gsap);

export default {
  name: "Engineering",
  components: {Statistics, RoundButton, Button},
  data: function () {
    return {
      redevelopInfo: [],
      play: false,
      play1: false
    }
  },
  created() {
    this.redevelopInfo = {
      btnText: 'Читать о компании',
    }
  },
  computed: {
    ...mapState({
      statisticsList: (state) => state.statistics.statisticsEngineering,
      //statisticsListTwo: (state) => state.statistics.statisticsListTwo
    })
  },
  mounted() {

    let self = this;
    // setTimeout(() => {
    //   const tlVueGsap = new TimelineMax()
    //       .from('.redevelop__title span:first-child', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
    //       .from('.redevelop__title span:last-child', 1, {y: 100, rotation: "8deg", opacity: 0, delay: -0.5, ease: "Power2.easeOut"})
    //       .from('.redevelop__img img', 1, {y: 300, delay: -0.5, opacity: 0, ease: "Power2.easeOut"})
    //   const scene5 = this.$scrollmagic
    //       .scene({
    //         triggerElement: ".redevelop",
    //         triggerHook: 0.5,
    //         reverse: false
    //       })
    //       .on('start', function () {
    //         setTimeout(() => {
    //           self.play = true;
    //         }, 1500)
    //
    //         setTimeout(() => {
    //           self.play1 = true;
    //         }, 2000)
    //       })
    //       .setTween(tlVueGsap)
    //
    //   this.$scrollmagic.addScene(scene5);
    //
    // }, 200)
  },

}
</script>

<style scoped>

</style>
