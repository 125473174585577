<template>
<!--  <div id="app" v-smooth-scroll="{ duration: 1000, offset: -50 }">-->
  <div id="app">
    <Header></Header>
    <transition name="fade">
    <router-view />
    </transition>
    <Footer></Footer>
    <transition name="fade">
        <Modal v-if="modalStatus"></Modal>
    </transition>
    <transition name="fadeOut">
      <div v-if="modalStatus" class="modal__bg"></div>
    </transition>
  </div>
</template>

<style lang="sass">
@import "./../node_modules/animate.css/animate.min.css"
@import "./../node_modules/animate.css/source/animate.css"
@import "assets/style/main"

h2
  overflow: hidden

h2 span
  display: block

</style>

<script>
import Header from "@/components/main/Header";
import Footer from "@/components/main/Footer";
import Modal from "@/components/main/Modal";
import {mapMutations, mapGetters, mapActions} from "vuex";
export default {
  components: {Footer, Header, Modal},
  computed: {
      ...mapGetters(['modalStatus', 'getPolicy'])
  },
  watch: {
    modalStatus(newVal) {
        if(newVal) {
            document.body.parentNode.style.overflow = "hidden";
        } else {
            document.body.parentNode.style.overflowY = "auto";
        }
    }
  },
  created () {
    // this.getBaseUrl();

    this.requestPolicy();
    window.addEventListener('scroll', this.handleScroll);
      if(this.modalStatus) {
          document.body.parentNode.style.overflow = "hidden";
      } else {
          document.body.parentNode.style.overflowY = "auto";
      }
  },
  mounted() {
    this.requestInfo();
    this.projectsRequest();
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapMutations(['setActivePage', 'setModalStatus']),
    ...mapActions(['requestVideo', 'projectsRequest', 'requestInfo', 'requestPolicy', 'getBaseUrl']),
    handleScroll () {

      if (window.pageYOffset > 250) {
        document.querySelector('.header__fixed').classList.remove('on-top');
        document.querySelector('.header__fixed').classList.add('fixed');
      } else {
        document.querySelector('.header__fixed').classList.remove('fixed');
        document.querySelector('.header__fixed').classList.add('on-top');
      }

    }
  }
}
</script>
