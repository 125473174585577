<template lang="pug">
  section.contact-form.contact-form--small
    form.form(@submit.prevent="sendData" :class="{success : success}")
      h2: div Хотите так же?
      .form-success(v-if="success")
        p.form-success__title Заявка оформлена
        p.form-success__text Ожидайте, в ближайшее время с вами свяжется наш специалист.
      .form-wrap(v-if="!success")
        .contact-form__row
          div.input-block(:class="{error : $v.form.userName.$error}")
            label.contact-form__label(for="contactFormName") Имя
            input(v-model="form.userName" type="text" id="contactFormName"
              @focus=""
              )
            span(v-if="($v.form.userName.$dirty && !$v.form.userName.required) ? 'заполните поле' : null").input-block__error заполните поле
        .contact-form__row(ref="telRow")
          div.input-block(:class="{error : $v.form.tel.$error}")
            label.contact-form__label(for="contactFormTel") Телефон
            input(type="tel"
              v-mask="'+7 (999) 999-99-99'"
              focus="$v.form.tel.$reset()"
              v-model="form.tel"
              placeholder="+7 (___) ___-__-__"
              id="contactFormTel"
            )
            span(v-if="($v.form.tel.$dirty && !$v.form.tel.required) ? 'заполните поле' : null").input-block__error заполните поле
            span.input-block__error(v-if="form.tel.replace(/[^+\\d]/g, '').length < 11 && form.tel.replace(/[^+\\d]/g, '').length > 0") заполните номер полностью

        button.button.button--big(type="submit") Связаться с нами

        .contact-form__row
          checkbox(
            id="checkPolicyContacts"
            @changeCheckbox="changeCheckbox($event)"
            :error="$v.form.checkbox.$error"
          )

</template>

<script>
import Button from "@/components/main/Button";
import Checkbox from "@/components/Checkbox";
import { required } from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from "vuex";

function telValidator (value) {
  return !(value.replace(/[^+\d]/g, '').length < 11)
}

export default {
name: "FeedbackFormSmall",
  components: {Checkbox, Button},
  data() {
    return {
      success: false,
      form: {
        userName: '',
        tel: '',
        checkbox: true
      },
      myForm: new FormData()
    }
  },
  validations: {
    form: {
      userName: {
        required
      },
      tel: {
        required,
        telValidator
      },
      checkbox: {
        checked (val) {
          return val
        }
      }
    }
  },
  methods: {
    ...mapActions(["sendForm"]),
    changeCheckbox(value) {
      this.form.checkbox = value;
    },
    changeInput(value, input) {
      this.form[input] = value;
    },
    sendData() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        //this.myForm.append('file', this.imageFile)
        this.myForm.append('userName', this.form.userName)
        this.myForm.append('email', '')
        this.myForm.append('tel', this.form.tel)
        this.myForm.append('comment', '')
        this.myForm.append('url', this.getUrl)

        this.sendForm(this.myForm);
        this.success = true;
        setTimeout(() => {
          this.success = false
        }, 2000)
      }
      // setTimeout(() => {
      //   this.success = false
      // },   2000)
    }
  },
  computed: {
    ...mapGetters(['getUrl'])
  }
}
</script>

<style scoped>

</style>
