import axios from "axios";
import store from '../index'

let state = {
    video: '',
    team: [],
    info: {
        title: '',
        text: [],
        stats1: [],
        stats2: [],
        image: ''
    },
    licence: [],
    licenceDescription: {},
    policy: '',
    homePage: {}
}

let mutations = {
    setVideo: (state, link) => {
        console.log(link)
        state.video = link.desktop_header_video
    },

    setTeam: (state, employee) => {
        state.team = employee
    },

    setInfo: (state, info) => {
        state.info.title = info.text.find(item => item.comment === 'Заголовок').value;
        state.info.image = info.desktop_photo;

        for (let p of info.text) {
            p.comment === 'Текст' ? state.info.text.push(p.value) : null
        }

        for (let stat of info.specification) {
            stat.sort === 1 ? state.info.stats1.push({value: stat.value.split(' ')[0], ending: stat.value.split(' ')[1] ? ' ' + stat.value.split(' ')[1] : null, name: stat.name}) : null
            stat.sort === 2 ? state.info.stats2.push({value: stat.value.split(' ')[0], ending: stat.value.split(' ')[1] ? ' ' + stat.value.split(' ')[1] : null, name: stat.name}) : null
        }
    },

    setPolicy: (state, info) => {
        state.policy = info.policy_file
    },

    setLicence: (state, list) => {
        state.licenceDescription = {
            title: list.find(item => item.title).title,
            text: list.find(item => item.title).description
        }

        for (let item of list) {
            state.licence.push(item.desktop_picture)
        }
    },

    setHomePage: (state, data) => {
        state.homePage = data
    }
}

let getters = {
    getVideo: state => state.video,

    getTeam: state => state.team,

    getInfo: state => state.info,

    getPolicy: state => state.policy,

    getLicence: state => state.licence,

    getLicenceDescription: state => state.licenceDescription,

    getHomePage: state => state.homePage
}

let actions = {
    requestVideo: ({commit}, state) => {
        axios
            .get(`${store.state.base_url}about`, {
                headers: store.state.headers
            })
            .then(({ data }) => {
                if (data.response.status === 'success') {
                    commit('setVideo', data.response.data[0])
                } else {
                    console.log('Video error')
                }
            })
            .catch(e => console.log(e))
    },

    requestPolicy: ({commit}, state) => {
        axios
            .get(`${store.state.base_url}about`, {
                headers: store.state.headers
            })
            .then(({ data }) => {
                if (data.response.status === 'success') {
                    commit('setPolicy', data.response.data[0])
                } else {
                    console.log('Video error')
                }
            })
            .catch(e => console.log(e))
    },

    requestTeam: ({commit}, state) => {
        axios
            .get(`${store.state.base_url}about`, {
                headers: store.state.headers
            })
            .then(({ data }) => {
                if (data.response.status === 'success') {
                    commit('setTeam', data.response.data[0].employee)
                } else {
                    console.log('Video error')
                }
            })
            .catch(e => console.log(e))
    },

    requestInfo: ({commit}, state) => {
        axios
            .get(`${store.state.base_url}about`, {
                headers: store.state.headers
            })
            .then(({ data }) => {
                if (data.response.status === 'success') {
                    commit('setInfo', data.response.data[0])
                } else {
                    console.log('Video error')
                }
            })
            .catch(e => console.log(e))
    },

    requestLicence: ({commit}, state) => {
        axios
            .get(`${store.state.base_url}license`)
            .then(({ data }) => {
                if (data.response.status === 'success') {
                    commit('setLicence', data.response.data)
                } else {
                    console.log('Video error')
                }
            })
            .catch(e => console.log(e))
    },

    requestHomePage: ({commit}, state) => {
        axios
            .get(`${store.state.base_url}home`)
            .then(({ data }) => {
                if (data.response.status === 'success') {
                    commit('setHomePage', data.response.data[0])
                } else {
                    console.log('Video error')
                }
            })
            .catch(e => console.log(e))
    }
}

export default {
    actions,
    state,
    mutations,
    getters
}
