<template lang="pug">
  ul.statistics.statistics--white(:class="newClass")
    li(v-for="(item, i) in statisticsList" :key="'item' + i")
      p.statistics__num(v-html="item.value")
      p(v-html="item.name")

</template>

<script>
export default {
name: "BigStatistics",
  props: {
    statisticsList: {
      type: Array,
      default() {
        return [];
      },
    },
    newClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>