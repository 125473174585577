import axios from "axios";
import store from '../index';

let state = {
        allProjects: [
            {
                category: {
                    name: '',
                    projects: [],
                    id: 1
                },
            },
            {
                category: {
                    name: '',
                    projects: [],
                    id: 2
                },
            },
            {
                category: {
                    name: '',
                    projects: [],
                    id: 3
                },
            },
            {
                category: {
                    name: '',
                    projects: [],
                    id: 4
                }
            }
        ],
        project: {},
        recommends: [],
        projectsForServices: [],
        categories: [
            {
                id: 1,
                title: 'Проекты редевелопмента',
                count: 6
            },
            {
                id: 2,
                title: 'Инженерное переоснащение',
                count: 6
            },
            {
                id: 3,
                title: 'Металлоконструкции',
                count: 6
            },
        ],
        mainPageProject: {}
    }
;

let getters = {
    getCategoriesData: state => state.categories,

    getProjects: state => state.projects,

    getProjectsDataById: state => id => state.allProjects[id-1].category.projects,

    getAllProjects: state => state.allProjects,

    getServiceProjects: state => state.projectsForServices,

    getOneProject: state => state.project,

    getRecommends: state => state.recommends,

    getMainPageProject: state => state.mainPageProject
}

let mutations = {
    setPortfolio(state, data) {
        state.categories = data.categories;
    },

    setAllProjects(state, data) {
        // добавляем категории и их дату
        state.allProjects[0].category.name = data[0].project_categories[0].name;
        state.allProjects[1].category.name = data[0].project_categories[1].name;
        state.allProjects[2].category.name = data[0].project_categories[2].name;
        // добавляем проекты по категориям
        for (let project of data) {
            switch (project.category_id) {
                case 1:
                    state.allProjects[0].category.projects.push(project);
                    break;
                case 2:
                    state.allProjects[1].category.projects.push(project);
                    break;
                case 3:
                    state.allProjects[2].category.projects.push(project);
                    break;
                case 4:
                    state.allProjects[3].category.projects.push(project);
                    break;
                default:
                    console.log('Добавьте категорию в админке.')
            }
            // заполняем массив объектов, которые выводятся на стр услуг
            if (project.service_category_id) {
                state.projectsForServices.push({
                    id: project.service_category_id,
                    image: project.desktop_picture,
                    title: project.name,
                    link: project.slug,
                })
            }

            if (project.show_on_main_page === 1) {
                state.mainPageProject = project;
                state.mainPageProject.result = project.text.find(item => item.comment === "Результат редевелопмента")
            }
            // сортировка проектов на главной
            state.projectsForServices.sort((a, b) => a.id - b.id);

            // сортировка проектов по порядку вывода
            for (let category of state.allProjects) {
                category.category.projects.sort((a, b) => a.id - b.id )
            }
        }
    },

    setOneProject(state, project) {
        // создаем объект 1 проекта
        state.project = project[0];
        // описание объекта
        state.project.description = {
            pre: [],
            about: [],
            before: [],
            after: [],
            state: [],
            price: [],
            stats: []
        }
        // дата объекта
        state.project.objYear = project[0].date.split('-')[0];
        state.project.objMonth = '';

        // перебор возможных месяцев
        switch (project[0].date.split('-')[1]) {
            case "01":
                state.project.objMonth = 'Январь';
                break;
            case "02":
                state.project.objMonth = 'Февраль';
                break;
            case "03":
                state.project.objMonth = 'Март';
                break;
            case "04":
                state.project.objMonth = 'Апрель';
                break;
            case "05":
                state.project.objMonth = 'Май';
                break;
            case "06":
                state.project.objMonth = 'Июнь';
                break;
            case "07":
                state.project.objMonth = 'Июль';
                break;
            case "08":
                state.project.objMonth = 'Август';
                break;
            case "09":
                state.project.objMonth = 'Сентябрь';
                break;
            case "10":
                state.project.objMonth = 'Октябрь';
                break;
            case "11":
                state.project.objMonth = 'Ноябрь';
                break;
            case "12":
                state.project.objMonth = 'Декабрь';
                break;
        }
        // создаем массив рекомендованного
        state.project.recs = [];
        // заполнение описания
        for (let text of state.project.text) {
            switch (text.comment) {
                case "Результат редевелопмента":
                    state.project.description.after.push(text.value);
                    break;
                case "Результат проекта":
                    state.project.description.after.push(text.value);
                    break;
                case "Состояние объекта":
                    state.project.description.state.push(text.value);
                    break;
                case "Предисловие":
                    state.project.description.pre.push(text.value);
                    break;
                case "Исходное состояние":
                    state.project.description.before.push(text.value);
                    break;
                case "стоимость объекта":
                    state.project.description.price.push(text.value);
                    break;
                case "Нижняя статистика":
                    state.project.description.stats.push({value: text.value.split(';')[0], name: text.value.split(';')[1]});
                    break;
            }
        }
    },

    setRecommends(state, data) {
        // очищаем массив рекомендованного, если открываем проект из слайдера рекомендованного
        state.recommends.splice(0, state.recommends.length);
        // заполняем массив рекомендованного
        for (let item of data) {
            if (state.project.name !== item.name && state.project.category_id === item.category_id) {
                state.recommends.push(item);
            }
        }
    }
};

let actions = {
// Запрос на все проекты
    projectsRequest: ({commit}, state) => {
        const url = `${store.state.base_url}portfolio`
        axios
            .get(url)
            .then(response => {
                commit('setAllProjects', response.data.response.data)
            })
            .catch(error => error)
    },

// Запрос на рекомендации
    recommendsRequest: ({commit}, state) => {
        const url = `${store.state.base_url}portfolio`
        axios
            .get(url)
            .then(response => {
                commit('setRecommends', response.data.response.data)
            })
            .catch(error => error)
    },

// Запрос одного проекта по его слагу
    oneProjectRequest: ({commit}, slug, state) => {
        const url = `${store.state.base_url}portfolio?slug=${slug}`
        axios
            .get(url)
            .then(response => {
                commit('setOneProject', response.data.response.data)
            })
            .catch(error => error)
    }
}


export default {
    state,
    getters,
    actions,
    mutations

};