<template lang="pug">
  section.invite
    .container.pl
      .invite__wrap
        .invite__left-side(v-if="data")
          h2
            span {{ data.tenderTitle.split(' ')[0] }}
            span {{ data.tenderTitle.split(' ').slice(1).join(' ') }}
          .invite__text-wrap
            .invite__text
              p {{ data.tenderShortDescription }}
          .invite__img
            img(:src="url + data.tenderDesktopPhoto" alt="")

        .invite__right-side
          FormBlock


</template>

<script>
import FormBlock from "@/components/FormBlock";

export default {
  name: "Invite",
  components: {FormBlock},
  props: {
    data: {
      type: Object
    },
    url: {
      type: String
    }
  },
  mounted() {
    // let self = this;
    // setTimeout(() => {
    //   const tlVueGsap = new TimelineMax()
    //       .from('.invite__left-side h2 span:first-child', 1, {
    //         y: 100,
    //         rotation: "8deg",
    //         opacity: 0,
    //         ease: "Power2.easeOut",
    //         reverse: false
    //       })
    //       .from('.invite__left-side h2 span:last-child', 1, {
    //         y: 100,
    //         rotation: "8deg",
    //         opacity: 0,
    //         delay: -0.5,
    //         ease: "Power2.easeOut",
    //         reverse: false
    //       })
    //       .from('.invite__text', 1, {y: 200, opacity: 0, rotation: "8deg", delay: -0.7, ease: "Power2.easeOut"})
    //       .from('.invite__img img', 1, {y: 600, opacity: 0, delay: -0.5, ease: "Power2.easeOut"})
    //   const scene4 = this.$scrollmagic
    //       .scene({
    //         triggerElement: ".invite",
    //         triggerHook: 0.5,
    //         reverse: false
    //       })
    //       .setTween(tlVueGsap)
    //
    //   this.$scrollmagic.addScene(scene4);
    //
    // }, 200)
  }
}
</script>

<style scoped>

.invite__img img{
  width: 100%;
  height: auto;
}

</style>
