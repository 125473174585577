<template lang="pug">
  section.pt.one-project-page(v-if="getOneProject")
    .container
      breadcrumbs(:breadcrumbsList="breadcrumbs")

      router-link(v-if="prevRoute" :to="prevRoute.matched.length ? prevRoute.path : '/portfolio'").back-link Назад
      Article(:img="getOneProject.desktop_picture" v-if="getOneProject" :title="getOneProject.name" :month="getOneProject.objMonth" :year="getOneProject.objYear" :address="getOneProject.address")
        big-statistics(:statistics-list="getOneProject.specification" new-class="article__statistics-list")

        .article-title(v-if="getOneProject.description || getOneProject.gallery.length || getOneProject.gallery1.length")
          h2 О проекте
        .article-elem.article-elem--initial(v-if="getOneProject.description.before.length || getOneProject.gallery.length")
          .article-wrap.article-wrap--initial
            h3 Исходное состояние
            p(v-for="(text, id) in getOneProject.description.before" :key="id" v-html="text")

            .article-block.article-block--state(v-if="getOneProject.description.state.length || getOneProject.gallery.length")
              div
                h4 Состояние объекта
                p(v-for="(elem, id) in getOneProject.description.state" :key="id") {{ elem }}
              .article-statistic(v-if="getOneProject.description.price.length")
                .article-statistic__num {{ getOneProject.description.price[0] }} р/м<sup>2</sup>
                .article-statistic__text стоимость объекта

            ul.article-gallery.article-gallery--state(v-if="getOneProject.gallery.length")
              li.article-gallery__item(v-for="(item, id) in getOneProject.gallery" @click="openGallery(getOneProject.gallery, id)")
                img(:src="getRootUrl + item.file_path" alt="")

        .article-elem.article-elem--result(v-if="getOneProject.description.after.length || getOneProject.gallery1.length")
          .article-wrap.article-wrap--result
            h3 Результат редевелопмента
            p(v-for="(elem, id) in getOneProject.description.after" :key="id") {{ elem }}

            ul.article-gallery(v-if="getOneProject.gallery1.length")
              li.article-gallery__item(v-for="(item, id) in getOneProject.gallery1" @click="openGallery(getOneProject.gallery1, id)")
                img(:src="getRootUrl + item.file_path" alt="")

            //span.article-sub-text *не считая срока экспозиции

            big-statistics(v-if="getOneProject.description.stats.length" :statistics-list="getOneProject.description.stats" new-class="article__statistics-small-list")

    feedback-form

    section.recommend(v-if="getRecommends.length")
      recommend-slider(:recomendProjects="getRecommends" :category="getOneProject.category_id")

</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs";
import Article from "@/components/oneProject/Article";
import BigStatistics from "@/components/BigStatistics";
import Button from "@/components/main/Button";
import Checkbox from "@/components/Checkbox";
import RecommendSlider from "@/components/RecommendSlider";
import { mapGetters, mapActions } from "vuex";
import Statistics from "@/components/Statistics";
import FeedbackForm from "@/components/oneProject/FeedbackForm";
import GalleryModal from "@/components/GalleryModal";

export default {
  name: "OneProject",
  components: {
    FeedbackForm,
    Statistics,
    RecommendSlider,
    Checkbox,
    Button,
    BigStatistics,
    Article,
    Breadcrumbs,
    GalleryModal,
  },
  data() {
    return {
      link: '',
      prevRoute: null
    }
  },

  computed: {
    ...mapGetters(['getRootUrl', 'getOneProject', 'getRecommends']),

    breadcrumbs() {
      return [
        {
          name: 'Главная',
          url: '/'
        },
        {
          name: 'Проекты',
          url: '/portfolio'
        },
        {
          name: this.getOneProject.name || null,
        }
      ]
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  methods: {
    openGallery(item, id) {
      if (document.documentElement.offsetWidth > 440) {
        this.$modal.show(GalleryModal, {
              imgSrc: item,
              index: id
            },
            {
              classes: 'gallery-modal-wrap',
              width: '100%',
              height: '100%',
            })
      }
    },

    ...mapActions(['oneProjectRequest', 'recommendsRequest'])
  },

  watch: {
    $route(){
      this.link = this.$route.params.id;

      this.oneProjectRequest(this.link);

      this.recommendsRequest();
      // this.project = this.$store.getters.getProjectsDataByLink(this.link);
    }
  },

  created() {
    this.link = this.$route.params.id;

    this.oneProjectRequest(this.link);

    this.recommendsRequest();

    // this.link = this.$route.params.id;
    // this.project = this.$store.getters.getProjectsDataByLink(this.link);
    // this.project = '1'
    // let self = this;
    // setTimeout(() => {
    //     let height = $('.article__title').height() + 100;
    //     const tlVueGsap = new TimelineMax()
    //         .from('.article__title', 1, {opacity: 0, y: height, rotation: "8deg", ease: "Power2.easeOut"})
    //         .from('.article__address', 0.5, {
    //             opacity: 0,
    //             y: 200,
    //             rotation: "8deg",
    //             delay: -0.5,
    //             ease: "Power2.easeOut"
    //         })
    //         .from('.article__img img', 1.5, {opacity: 0, y: 420, delay: -0.3, ease: "Power2.easeOut"})
    //         .from('.article__date', 0.5, {opacity: 0, delay: -0.3, ease: "Power2.easeOut"})
    //     const tlVueGsap2 = new TimelineMax()
    //         .from('.article-title h2', 1, {opacity: 0, y: 200, rotation: "8deg", ease: "Power2.easeOut"})
    //     const scene4 = this.$scrollmagic
    //         .scene({
    //             triggerElement: ".article",
    //             triggerHook: 0.8,
    //             reverse: false
    //         })
    //         .setTween(tlVueGsap)
    //
    //     this.$scrollmagic.addScene(scene4);
    //
    //     const scene5 = this.$scrollmagic
    //         .scene({
    //             triggerElement: ".article__info",
    //             triggerHook: 0.8,
    //             reverse: false
    //         })
    //         .setTween(tlVueGsap2)
    //
    //     this.$scrollmagic.addScene(scene5);
    //
    //     let height2 = $('.article-wrap--initial').height() + 100;
    //     const tlVueGsap3 = new TimelineMax()
    //         .from('.article-wrap--initial', 1.3, {opacity: 0, y: height2, delay: 0.7, ease: "Power2.easeOut"})
    //
    //     const scene6 = this.$scrollmagic
    //         .scene({
    //             triggerElement: ".article-elem--initial",
    //             triggerHook: 0.8,
    //             reverse: false
    //         })
    //         .setTween(tlVueGsap3)
    //
    //     this.$scrollmagic.addScene(scene6);
    //
    //     let height3 = $('.article-wrap--result').height() + 100;
    //     const tlVueGsap4 = new TimelineMax()
    //         .from('.article-wrap--result', 1.3, {opacity: 0, y: height3, ease: "Power2.easeOut"})
    //
    //     const scene7 = this.$scrollmagic
    //         .scene({
    //             triggerElement: ".article-elem--result",
    //             triggerHook: 0.8,
    //             reverse: false
    //         })
    //         .setTween(tlVueGsap4)
    //
    //     this.$scrollmagic.addScene(scene7);
    //
    //
    // }, 200)
  },
}
</script>

<style scoped>

</style>
