<template lang="pug">
  div.service-page__wrap
    div.services-bar
      .container
        ul.services-bar__wrap
          li.services-bar__item(v-for="category in GetCategories" :key="category.id" v-if="category.id <= GetServices.length")
            a(:href="'/services#0' + category.id") {{ category.name }}
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ServicesBar",

  created() {
    setTimeout( () => {
      const scene1 = this.$scrollmagic
          .scene({
            triggerElement: ".services-bar",
            triggerHook: 0.01,
            duration: 0,
          })
          //.setClassToggle(".services-bar", "fixed")
          .setTween(".services-bar", {
            css: {
              //position: "fixed",
            },
          })


      this.$scrollmagic.addScene(scene1);
    }, 300 );
  },
  mounted() {
    const list = document.querySelector('.services-bar__wrap')

    setTimeout(() => {
      if (document.documentElement.offsetWidth < 1024) {
        list.style = "animation: scrollSide 1.2s ease-in-out";
      }
    }, 800)
  },
  computed: {
    ...mapGetters(['GetCategories', 'GetServices']),
  }

}

</script>

<style scoped>

</style>