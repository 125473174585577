<template lang="pug">
  section.redevelop
    .container.pl(v-if="getInfo" )
      .redevelop__wrap
        .redevelop__left-side
          h2.redevelop__title
            span {{ getInfo.title }}
            span
          .redevelop__text
            p {{ getInfo.text[0] }}

          statistics(:statisticsList="getInfo.stats1" :trigger='play')

          RoundButton(:text="'Читать о компании'" link="/about")

        .redevelop__right-side
          .redevelop__img
            img(:src="getRootUrl + getInfo.image" alt="")
          .redevelop__info
            p {{ getInfo.text[1] }}
          statistics(:statisticsList="getInfo.stats2" :trigger='play2')




</template>

<script>
import Button from "@/components/main/Button";
import RoundButton from "@/components/main/RoundButton";
import Statistics from "@/components/Statistics";
import  { mapGetters, mapActions } from "vuex";

// import * as ScrollMagic from "scrollmagic";
// import {gsap} from "gsap";
// import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
//
// ScrollMagicPluginGsap(ScrollMagic, gsap);

export default {
  name: "Redevelopment",
  components: {Statistics, RoundButton, Button},
  data: function () {
    return {
      redevelopInfo: [],
      play: false,
      play2: false,
      statistics: [],
      statisticsTwo: [],
    }
  },
  created() {
    this.requestInfo()
    this.redevelopInfo = {
      btnText: 'Читать о компании',
    }
    this.statistics = [
      {
        value: '9 лет',
        ending: " лет",
        timing: 2,
        name: 'работы'
      },
      {
        value: '60+',
        ending: "+",
        timing: 2,
        name: 'проектов реализованно'
      },
      {
        value: '100%',
        ending: "%",
        timing: 2,
        name: 'завершенных проектов'
      }

    ]
    this.statisticsTwo = [
      {
        value: '15',
        ending: "",
        timing: 2,
        name: 'ИТР'
      },
      {
        value: '150+',
        ending: "+",
        timing: 2,
        name: 'прорабов'
      },
      {
        value: '17',
        ending: "",
        timing: 2,
        name: 'бригад'
      }

    ]
  },
  computed: {
    ...mapGetters(['getInfo', 'getRootUrl'])
    // ...mapState({
    //   statisticsList: (state) => state.statistics.statisticsList,
    //   statisticsListTwo: (state) => state.statistics.statisticsListTwo
    // })
  },
  methods: {
    ...mapActions([''])
  },
  mounted() {

    let self = this;
    setTimeout(() => {
      const tlVueGsap = new TimelineMax()
          .from('.redevelop__title span:first-child', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
          .from('.redevelop__title span:last-child', 1, {y: 100, rotation: "8deg", opacity: 0, delay: -0.5, ease: "Power2.easeOut"})
          .from('.redevelop__img img', 1, {y: 300, delay: -0.5, opacity: 0, ease: "Power2.easeOut"})
      const scene4 = this.$scrollmagic
          .scene({
            triggerElement: ".redevelop",
            triggerHook: 0.5,
            reverse: false
          })
          .on('start', function () {
            setTimeout(() => {
              self.play = true;
            }, 1500)

            setTimeout(() => {
              self.play2 = true;
            }, 2000)
          })
          .setTween(tlVueGsap)

      this.$scrollmagic.addScene(scene4);

    }, 200)
  },

}
</script>

<style scoped>

</style>
