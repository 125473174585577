<template lang="pug">
  ul.pages
    li.pages__item(v-for="(item, id) in num" :key="id" :class="{active : activeItem === id+1, one: id === 0, two: id === 1, three: id === 2, four: id === 3, five: id === 4, six: id === 5, seven: id === 6, eight: id === 7,}")
      | {{ item }}
</template>

<script>
export default {
name: "Sidebar",
  props: [
      'page', 'activeItem', 'num'
  ]
}
</script>

<style scoped>

</style>