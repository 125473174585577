<template lang="pug">
  router-link.round-button(:to="link" :class="[addClass, 'round-button--' + color ]")
    div.round-btn__icon
      svg(width='55' height='40' viewBox='0 0 55 40' fill='none' xmlns='http://www.w3.org/2000/svg')
        circle(class="circle" opacity='0.6' cx='24' cy='20' r='19.85' stroke='#3E4DDB' stroke-width='0.3')
        circle(class="circle2" opacity='0.4' cx='20' cy='20' r='19.85' stroke='#3E4DDB' stroke-width='0.3')
        circle(class="circle" opacity='0.7' cx='28' cy='20' r='19.8' stroke='#3E4DDB' stroke-width='0.4')
        circle(class="circle" opacity='0.9' cx='32' cy='20' r='19.75' stroke='#3E4DDB' stroke-width='0.5')
        circle(class="circle" cx='35' cy='20' r='19.8' fill='#3E4DDB' stroke='#3E4DDB' stroke-width='0.4')
        path(d='M42 20.3535L38.321 17L37.4045 17.7729L39.5105 19.6866H29V20.9332H39.5105L37.4045 23.065L38.321 24L42 20.3535Z' fill='white')
    span {{ text }}
</template>

<script>
export default {
name: "RoundButton",
  props: [
    'text', 'addClass', 'color', 'link'
  ],

}
</script>

<style scoped>

</style>