import Vue from "vue";
import Vuex from "vuex";
import axios from "axios"
import statistics from "@/store/modules/statistics";
import services from "@/store/modules/services";
import projects from "@/store/modules/projects";
import contacts from "@/store/modules/contacts";
import about from "@/store/modules/about";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    scroll_forbidden: false,
    activePage: 'index',
    base_url: "https://grade.company/api/",
    root_url: "https://grade.company",
    modalStatus: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    fullUrl: window.location.href
  },
  mutations: {
    setScrollStatus(state, data) {
      state.scroll_forbidden = data;
    },
    setActivePage(state, data) {
      state.activePage = data;
    },
    setModalStatus(state, data) {
      state.modalStatus = data;
      this.success = false;
    },
  },
  getters: {
    modalStatus: state => {
      return state.modalStatus;
    },

    getUrl: state => state.fullUrl,

    getRootUrl: state => state.root_url
  },
  actions: {
    sendForm({ commit, state }, form) {
      return new Promise(resolve => {
        const url = "/api/home/first-form";
        axios
            .post(url, form, {
              headers: {
                "Content-Type": "multipart/form-data"
             }
            })
            .then(response => {
              console.log(form)
              console.log(response)
              if (response.data.response.status === "success") {
                resolve()
              } else {
                reject()
              }
            })
            .catch(e => console.log(e));
      })
    },
    getBaseUrl(state) {
// -------------- для запуска на локалхосте убрать 'https://' --------------
//       state.base_url = "https://grade.company/api/"
//       state.root_url = "https://" + window.location.hostname
    }
  },
  modules: {
    statistics,
    services,
    projects,
    contacts,
    about
  }
});
