<template lang="pug">
  section.intro.pt(id="1")
    div.intro-bg(v-if="data")
      svg(width="245" height="741" viewBox="0 0 245 741" fill="none" xmlns="http://www.w3.org/2000/svg")
        path(opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M122.605 0.0453017L122.5 0L122.395 0.0453017L0.610973 52.7172V52.5107H0V741H0.610973V740.529L122.5 687.812L244.389 740.529V741H245V52.5107H244.389V52.7172L122.605 0.0453017ZM0.610973 739.759L122.395 687.087L122.5 687.041L122.605 687.087L244.389 739.759V687.68L122.5 634.963L0.610973 687.68V739.759ZM0.610973 686.909L122.395 634.237L122.5 634.192L122.605 634.237L244.389 686.909V634.831L122.5 582.113L0.610973 634.831V686.909ZM0.610973 634.06L122.395 581.388L122.5 581.343L122.605 581.388L244.389 634.06V581.981L122.5 529.264L0.610973 581.981V634.06ZM0.610973 581.211L122.395 528.539L122.5 528.493L122.605 528.539L244.389 581.211V529.132L122.5 476.415L0.610973 529.132V581.211ZM0.610973 528.361L122.395 475.689L122.5 475.644L122.605 475.689L244.389 528.361V476.283L122.5 423.565L0.610973 476.283V528.361ZM0.610973 475.512L122.395 422.84L122.5 422.795L122.605 422.84L244.389 475.512V423.433L122.5 370.716L0.610973 423.433V475.512ZM0.610973 422.663L122.395 369.991L122.5 369.945L122.605 369.991L244.389 422.663V370.584L122.5 317.867L0.610973 370.584V422.663ZM0.610973 369.813L122.395 317.141L122.5 317.096L122.605 317.141L244.389 369.813V317.735L122.5 265.017L0.610973 317.735V369.813ZM0.610973 316.964L122.395 264.292L122.5 264.247L122.605 264.292L244.389 316.964V264.885L122.5 212.168L0.610973 264.885V316.964ZM0.610973 264.115L122.395 211.443L122.5 211.397L122.605 211.443L244.389 264.115V212.036L122.5 159.319L0.610973 212.036V264.115ZM0.610973 211.265L122.395 158.593L122.5 158.548L122.605 158.593L244.389 211.265V159.187L122.5 106.469L0.610973 159.187V211.265ZM0.610973 158.416L122.395 105.744L122.5 105.699L122.605 105.744L244.389 158.416V106.337L122.5 53.62L0.610973 106.337V158.416ZM0.610973 105.567L122.395 52.8946L122.5 52.8493L122.605 52.8946L244.389 105.567V53.4879L122.5 0.77068L0.610973 53.4879V105.567Z" fill="#42413F")
    .container.pl(v-if="data")

      .intro__wrap
        .intro__top
          GradeCircle
          div.intro__title
              p {{ data.title.split(' ')[0] }} {{ data.title.split(' ')[1] }}
              h1 {{ data.title.split(' ').slice(2).join(' ')}}

          .intro__info
            p {{ data.shortDescription }}

            Button(
              @click="setModalStatus(true)"
              :text="introInfo.btnText"
              :addClass="introInfo.class"
              size="small"
            )

        ul.intro__services.intro__services-desktop
          li.intro__services-item(v-for="(service, id) in GetCategories" :key="id")
            router-link(:to="'/services#0' + service.id") {{ service.name }}
              .intro__services__item-icon
                svg(width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg")
                  path(opacity="0.75" d="M21.2061 21.2132L19.2121 21.2414V10.4793L7.77817 21.9132L6.36396 20.499L17.7979 9.06507H7.06395L7.06399 7.07102C12.24 7.07102 16.2564 7.07102 21.2061 7.07102C21.2132 12.6784 21.2132 8.71858 21.2061 21.2132Z" fill="#F4F4F4")
        .intro__img.intro__img-desktop
          img(:src="url + data.desktopPhoto" alt="")


    .intro__img.intro__img-mobile(v-if="data")
      img(:src="url + data.desktopPhoto" alt="")

    .container(v-if="data")
      ul.intro__services.intro__services-mobile
        li.intro__services-item(v-for="(service, id) in GetCategories" :key="id")
          router-link(:to="'/services#0' + service.id") {{ service.name }}
            .intro__services__item-icon
              svg(width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg")
                path(opacity="0.75" d="M21.2061 21.2132L19.2121 21.2414V10.4793L7.77817 21.9132L6.36396 20.499L17.7979 9.06507H7.06395L7.06399 7.07102C12.24 7.07102 16.2564 7.07102 21.2061 7.07102C21.2132 12.6784 21.2132 8.71858 21.2061 21.2132Z" fill="#F4F4F4")
</template>

<script>
import Button from "@/components/main/Button";
import GradeCircle from "@/components/main/GradeCircle";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
name: "Intro",
  components: {
    GradeCircle,
    Button
  },
  data: function () {
    return {
      introInfo: []
    }
  },

  props: {
    data: {
      type: Object
    },
    url: {
      type: String
    }
  },

  computed: {
    ...mapGetters(['GetCategories'])
  },

  methods: {
    ...mapActions(['getServicesFromApi']),

    ...mapMutations(["setModalStatus"])
  },
  created() {
    this.getServicesFromApi();

    this.introInfo = {
      btnText: 'В тендер',
      class: 'intro__btn',
      size: 'small'
    }
  }
}
</script>

<style scoped>

</style>