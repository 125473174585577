<template lang="pug">
  div.services-page
    intro-block(
      url='intro_img_2.png'
      title="Услуги"
      :link="prevRoute"
    )
    services-bar

    service-item(:id="'0' + service.category_id"
                 :class="{first: service.category_id === 1, two: service.category_id === 2, three: service.category_id === 3, four: service.category_id === 4, five: service.category_id === 5, six: service.category_id === 6}"
                 v-for="(service, index) in GetServices" :key="index" :service-info="service" :project="getServiceProjects[index]")
      service-item-list(
        :service-info="service"
        :categories-num="GetServices.length"
      )

      statistics(:statisticsList="service.specification" v-if="service.specification.length" :trigger="play1")
</template>

<script>
import IntroBlock from "@/components/portfolio/IntroBlock";
import ServicesBar from "@/components/Services/ServicesBar";
import ServiceItem from "@/components/Services/ServiceItem";
import Statistics from "@/components/Statistics";
import ServiceItemSlider from "@/components/Services/ServiceItemSlider";
import ServiceItemList from "@/components/Services/ServiceItemList";
import RoundButton from "@/components/main/RoundButton";
import ServiceGallery from "@/components/Services/ServiceGallery";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import SwiperMobile from "@/components/SwiperMobile";

import $ from 'jquery';

export default {
name: "Services",
  data() {
    return {
      play1: false,
      play11: false,
      play12: false,
      play13: false,
      play14: false,
      prevRoute: null
    }
  },
  props: {
    link: String
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  components: {
    SwiperMobile,
    ServiceGallery,
    RoundButton, ServiceItemList, ServiceItemSlider, Statistics, ServiceItem, ServicesBar, IntroBlock},

  computed: {
    ...mapState({
      serviceOne: (state) => state.services.serviceOne,
      serviceTwo: (state) => state.services.serviceTwo,
      serviceThree: (state) => state.services.serviceThree,
      serviceFour: (state) => state.services.serviceFour,
    }),
    ...mapGetters(['GetServices', 'getServiceProjects'])
  },

  created() {
    this.getServicesFromApi();
  },

  mounted() {
    this.setActivePage('Услуги');

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: document.getElementById(this.$route.hash.split('#').join('')).offsetTop,
        behavior: 'smooth'
      })
    }, 1000)

    let self = this;
    setTimeout(() => {
      const scene3 = this.$scrollmagic
          .scene({
            triggerElement: ".first",
            triggerHook: 0.7,
            reverse: false
          })
          .on('start', function () {
            setTimeout(() => {
              self.play1 = true;
            }, 1500);
          })
      this.$scrollmagic.addScene(scene3);

      const scene41 = this.$scrollmagic
          .scene({
            triggerElement: ".services-page .service-page__wrap",
            triggerHook: 0.17,
            reverse: true
          })
          .setClassToggle(".services-bar", "fixed");

      this.$scrollmagic.addScene(scene41);

      let height1 = $(".first").height() + $(".first").offset().top + 100;
      const scene42 = this.$scrollmagic
          .scene({
            triggerElement: "#app",
            triggerHook: 0.6,
            duration: height1 ,
            reverse: true
          })
          .setClassToggle(".services-bar__item:first-child", "active")

      this.$scrollmagic.addScene(scene42);

      let height2 = $(".two").height();
      const scene43 = this.$scrollmagic
          .scene({
            triggerElement: ".two",
            triggerHook: 0.25,
            duration: height2,
            reverse: true
          })
          .setClassToggle(".services-bar__item:nth-child(2)", "active")

      this.$scrollmagic.addScene(scene43);

      let height3 = $(".three").height();
      const scene44 = this.$scrollmagic
          .scene({
            triggerElement: ".three",
            triggerHook: 0.25,
            duration: height3
          })
          .setClassToggle(".services-bar__item:nth-child(3)", "active")

      this.$scrollmagic.addScene(scene44);


      let height4 = $(".four").height();
      const scene45 = this.$scrollmagic
          .scene({
            triggerElement: ".four",
            triggerHook: 0.25,
            duration: height4
          })
          .setClassToggle(".services-bar__item:nth-child(4)", "active")

      this.$scrollmagic.addScene(scene45);

      // let height5 = $(".five").height();
      // const scene46 = this.$scrollmagic
      //     .scene({
      //       triggerElement: ".five",
      //       triggerHook: 0.25,
      //       duration: height5 - 50
      //     })
      //     .setClassToggle(".services-bar__item:nth-child(5)", "active")
      // this.$scrollmagic.addScene(scene46);
      //
      // let height6 = $(".six").height();
      // const scene47 = this.$scrollmagic
      //     .scene({
      //       triggerElement: ".six",
      //       triggerHook: 0.25,
      //       duration: height6 - 50
      //     })
      //     .setClassToggle(".services-bar__item:nth-child(6)", "active")
      // this.$scrollmagic.addScene(scene47);

      function createAnimation(elem) {
        const tlVueGsap = new TimelineMax()
            .from('' + elem + ' .service-item__title h2 span:first-child', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
            .from('' + elem + ' .service-item__title h2 span:last-child', 1, {
              y: 100,
              rotation: "8deg",
              opacity: 0,
              delay: -0.5,
              ease: "Power2.easeOut"
            })
            .from('' + elem + ' .button', 0.4, {
              y: 100,
              rotation: "8deg",
              opacity: 0,
              delay: -0.7,
              ease: "Power2.easeOut"
            })
        const scene = self.$scrollmagic
            .scene({
              triggerElement: ""+ elem +"",
              triggerHook: 0.8,
              reverse: false
            })
            .setTween(tlVueGsap)

        self.$scrollmagic.addScene(scene);

        const tlVueGsap2 = new TimelineMax()
            .from('' + elem + ' .service-item__img', 1.2, {y: 400, opacity: 0, delay: 1.2, ease: "Power2.easeOut"})
        const scene2 = self.$scrollmagic
            .scene({
              triggerElement: ""+ elem +"",
              triggerHook: 0.5,
                reverse: false
            })
            .setTween(tlVueGsap2)

        self.$scrollmagic.addScene(scene2);
      }

      createAnimation('.first');
      createAnimation('.two');
      createAnimation('.three');
      createAnimation('.four');
      // createAnimation('.five');
      // createAnimation('.six');

      let h3Arr = $('.services-page h3');
      for ( let [index, title] of Array.from(h3Arr).entries() ) {
        $(title).attr('data-title', index);
      }

      let arr = document.querySelectorAll("[data-title]")

      for (let elem of arr) {
        createAnimationTitle(elem.dataset.title)
      }

      function createAnimationTitle(elem) {
        let closestElem = $('h3[data-title=' + elem + ']').closest('.service-item__text')
        const tlVueGsap3 = new TimelineMax()
            .from('h3[data-title="' + elem + '"] span', 1.2,{y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
        const scene = self.$scrollmagic
            .scene({
              triggerElement: closestElem[0],
              triggerHook: 0.8,
                reverse: false
            })
            .setTween(tlVueGsap3)

        self.$scrollmagic.addScene(scene);
      }

    }, 2000)
  },
  methods: {
    ...mapMutations(['setActivePage']),
    ...mapActions(['getServicesFromApi'])
  }
}
</script>

<style scoped>

</style>