<template lang="pug">
  div.recommend-slider
    .container
      h2 Рекомендуем посмотреть следующие проекты

      swiper(ref="mySwiperRecommend" :options="swiperRecommendOptions" v-if="recomendProjects.length > 2")
        swiper-slide(v-for="(item, id) in recomendProjects" :key="id")
          router-link(:to="item.slug" @click="goToProject(item.slug, getAllProjects[category-1].category.projects.filter(elem => elem.slug !== item.slug))"
            :data-project="item.id")
            div.recommends-slider__wrap
              img(:src="'https://grade.company/' + item.desktop_picture" :alt="item.name")
            p {{ item.name }}

      div(v-if="recomendProjects.length <= 2").recommend-slider__container
        router-link(:to="item.slug" v-for="(item, id) in recomendProjects" :key="id" @click="goToProject(item.slug, getAllProjects[category-1].category.projects.filter(elem => elem.slug !== item.slug))"
          :data-project="item.id")
          div.recommends-slider__wrap
            img(:src="'https://grade.company/' + item.desktop_picture" :alt="item.name")
          p {{ item.name }}

      .swiper-pagination-service(slot="pagination" v-if="recomendProjects.length > 2")

      .swiper__navigation(v-if="recomendProjects.length > 2")
        .swiper-button-prev.service-swiper-btn-prev(slot="button-prev")
        .swiper-button-next.service-swiper-btn-next(slot="button-next")
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapGetters } from "vuex";

export default {
name: "RecommendSlider",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    ServiceInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    recomendProjects: {
      type: Array,
      default() {
        return [];
      },
    },
    category: Number
  },
  data() {
    return {
      swiperRecommendOptions: {
        slidesPerView: 2,
        loop: true,
        spaceBetween: 40,
        speed: 500,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination-service',
          type: 'bullets'
        },
        navigation: {
          nextEl: '.service-swiper-btn-next',
          prevEl: '.service-swiper-btn-prev'
        },
        breakpoints: {
          // when window width is >= 320px
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
            pagination: {
              type: 'fraction'
            },
          },
          320: {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: true,
            pagination: {
              type: 'bullets'
            },
          }
        }
      },
    }
  },
  computed: {
    ...mapGetters(['getAllProjects']),
    swiper() {
      return this.$refs.mySwiperRecommend.$swiper
    },
  },
  mounted() {
    this.swiper.init();
  },
  methods: {
    goToProject(link, categ) {
      console.log(link, categ)
      this.$router.push({ name: 'OneProject', params: { id: link, recommended: categ }})
    },
  }


}
</script>

<style scoped>

</style>