<template lang="pug">
  .page404
    .page404__content
      p 404
      span.page404__content-text К сожалению, такой страницы нет на сайте.
      RoundButton(text="Вернуться на главную страницу" color="white" link="/")
</template>

<script>
import RoundButton from "@/components/main/RoundButton";
export default {
  components: {RoundButton}
}
</script>