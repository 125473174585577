<template lang="pug">
  .contacts-page.pt
    section.about-intro.contacts-page__intro
      contacts-map
      .container.contacts__item
        .about-intro__left-side
          router-link(v-if="prevRoute.matched.length" :to="prevRoute.path").about-intro__back Назад
          router-link(v-if="!prevRoute.matched.length" :to="prevRoute.path").about-intro__back Главная
          h1.title-h1
            div Контакты

    section.contacts-info(v-if="getContactInfo")
      .container
        breadcrumbs(:breadcrumbsList="breadcrumbs")
        .contacts-info__wrap
          .contacts-info__left-side
            h2: div Адрес офиса

            ul.contacts__list
              li.contacts__item.contacts__address
                p {{ getContactInfo.address }}
                p.highlight {{ getContactInfo.object_name }}
              li.contacts__item.contacts__tel
                a(:href="'tel:' + getContactInfo.phone.replace(/\\s/g, '')") {{ getContactInfo.phone }}


            .contacts__text
              p {{ getContactInfo.description }}

            ul.contacts__statistic
              li.contacts__statistic-item
                p.contacts__statistic-num {{ getContactInfo.square }}<sup>2</sup>
                p.contacts__statistic-text общая площадь
              li.contacts__statistic-item
                p.contacts__statistic-num {{ getContactInfo.period }}
                p.contacts__statistic-text период работ

          .contacts-info__right-side
            feedback-form-small
</template>

<script>
import Breadcrumbs from "@/components/main/Breadcrumbs";
import FeedbackFormSmall from "@/components/FeedbackFormSmall";
import { mapMutations, mapActions, mapGetters } from "vuex";
import ContactsMap from "@/components/ContactsMap";

export default {
  name: "Contacts",
  components: {ContactsMap, FeedbackFormSmall, Breadcrumbs },
  data() {
    return {
      breadcrumbs: [],
      prevRoute: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getContactInfo'])
  },
  created() {
    this.breadcrumbs = [
      {
        name: 'Главная',
        url: '/'
      },
      {
        name: 'Контакты',
      }
    ]
  },
  mounted() {
    this.setActivePage('Контакты');
      // setTimeout(() => {
      //     const tlVueGsap1 = new TimelineMax()
      //         .to('.about-intro', 1, {height: '500px', delay: 0.3, ease: "Power2.easeOut"})
      //         .from('#map', 0.8, {
      //             delay: -0.3,
      //             opacity: 0,
      //             ease: "Power2.easeOut"
      //         })
      //         .from('.about-intro .title-h1 div', 0.8, {
      //             y: 100,
      //             delay: -0.3,
      //             rotation: "8deg",
      //             opacity: 0,
      //             ease: "Power2.easeOut"
      //         })
      //
      //     const scene1 = this.$scrollmagic
      //         .scene({
      //             triggerElement: ".contacts-page",
      //             triggerHook: 0.8,
      //             reverse: false
      //         })
      //         .setTween(tlVueGsap1)
      //     this.$scrollmagic.addScene(scene1);
      //     const tlVueGsap2 = new TimelineMax()
      //         .from('.contacts-info__left-side h2 div', 0.8, {
      //             y: 100,
      //             delay: -0.3,
      //             rotation: "8deg",
      //             opacity: 0,
      //             ease: "Power2.easeOut"
      //         })
      //         .from('.contact-form h2 div', 0.8, {
      //             y: 100,
      //             delay: -0.3,
      //             rotation: "8deg",
      //             opacity: 0,
      //             ease: "Power2.easeOut"
      //         })
      //     const scene2 = this.$scrollmagic
      //         .scene({
      //             triggerElement: ".contacts-page .contacts-info__left-side",
      //             triggerHook: 0.8,
      //             reverse: false
      //         })
      //         .setTween(tlVueGsap2)
      //     this.$scrollmagic.addScene(scene2);
      // }, 200)
    this.requestContactsInfo()
  },
  methods: {
    ...mapMutations(['setActivePage']),
    ...mapActions(['requestContactsInfo'])
  }
}
</script>

<style scoped>

</style>
