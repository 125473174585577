<template lang="pug">
    .about-page.pt
        section.about-intro
            //button.about-intro__video-btn(v-if="!isPlaying" @click="playVideo")
            //    svg(width='88' height='88' viewbox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg').desc-play
            //        circle(cx='44' cy='44' r='43' stroke='white' stroke-width='2')
            //        path(d='M50 44.6L41 37.3254V51.8746L50 44.6Z' fill='white')
            //
            //    svg(width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg").mob-play
            //        circle(cx="22" cy="22" r="21" stroke="white" stroke-width="2")
            //        path(d="M25 22.3L20.5 18.6627V25.9373L25 22.3Z" fill="white")


            //button.about-intro__video-btn(v-if="isPlaying" @click="stop")
            //    svg(width='88' height='88' viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg').desc-pause
            //        circle(cx='44' cy='44' r='43' stroke='white' stroke-width='2')
            //        line(x1='41' y1='36' x2='41' y2='52' stroke='white' stroke-width='2')
            //        line(x1='49' y1='36' x2='49' y2='52' stroke='white' stroke-width='2')
            //
            //    svg(width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg").mob-pause
            //        circle(cx="22" cy="22" r="21" stroke="white" stroke-width="2")
            //        line(x1="21" y1="18" x2="21" y2="26" stroke="white" stroke-width="2")
            //        line(x1="25" y1="18" x2="25" y2="26" stroke="white" stroke-width="2")



            div.about-intro__video
                video(ref="video" loop="loop" muted="" preload="auto" autoplay="autoplay" webkit-playsinline playsinline v-if="getVideo" )
                    source(:src='getRootUrl + getVideo')
                    //source(src="https://grad.grokhotov.ru/img/video/gradpro-belyj-fon.mp4")
            .container
                .about-intro__left-side
                    router-link(v-if="prevRoute.matched.length" :to="prevRoute.path" :class="{upper: isPlaying}").about-intro__back Назад
                    router-link(v-if="!prevRoute.matched.length" to="/" :class="{upper: isPlaying}").about-intro__back Главная
                    h1(:class="{upper: isPlaying}").title-h1
                        div О компании

        section.about-page__info
            .about-page__bg
                // вставить svg, которое будет на фоне

        .container
            breadcrumbs(:breadcrumbsList="breadcrumbs")

            .about-page__body
                h2
                    div  {{ getInfo.title }}

                .about-page__text
                    p {{ getInfo.text[0] }}

                    statistics(:statisticsList="getInfo.stats1" :trigger='play')

                    .about-page__img
                        img(:src="getRootUrl + getInfo.image" alt="")

                    p {{ getInfo.text[1] }}

                    statistics(:statisticsList="getInfo.stats2" addClass="about-page__small-statistic" :trigger='play2')


        team(:team="getTeam" :url="getRootUrl")
        license
        contacts
</template>

<script>
import Team from "@/components/Team";
import License from "@/components/License";
import Contacts from "@/components/oneProject/Contacts";
import IntroBlock from "@/components/portfolio/IntroBlock";
import Breadcrumbs from "@/components/main/Breadcrumbs";
import Statistics from "@/components/Statistics";
import { mapMutations, mapGetters, mapActions } from "vuex";
import * as ScrollMagic from "scrollmagic";
import {gsap} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";

export default {
    name: "About",
    components: {Statistics, Breadcrumbs, IntroBlock, Contacts, License, Team},
    data() {
        return {
            breadcrumbs: [],
            statistics: [],
            statisticsTwo: [],
            isPlaying: false,
            play: false,
            play2: false,
            prevRoute: null
        }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
      })
    },
    computed: {
      ...mapGetters(['getVideo', 'getRootUrl', 'getTeam', 'getInfo'])
    },
    mounted() {

        this.setActivePage('О компании');
        const self = this;
        setTimeout(() => {
            const tlVueGsap1 = new TimelineMax()
                .to('.about-intro', 1, {height: '500px', delay: 0.3, ease: "Power2.easeOut"})
                .from('.about-intro__left-side .title-h1 div', 0.8, {
                    y: 100,
                    delay: -0.3,
                    rotation: "8deg",
                    opacity: 0,
                    ease: "Power2.easeOut"
                })
            const scene1 = this.$scrollmagic
                .scene({
                    triggerElement: ".about-intro",
                    triggerHook: 0.8,
                    reverse: false
                })
                .setTween(tlVueGsap1)
            this.$scrollmagic.addScene(scene1);
            const tlVueGsap2 = new TimelineMax()
                .from('.about-page__body h2 div', 0.8, {
                    y: 100,
                    delay: -0.3,
                    rotation: "8deg",
                    opacity: 0,
                    ease: "Power2.easeOut"
                })
            const scene2 = this.$scrollmagic
                .scene({
                    triggerElement: ".about-page__body",
                    triggerHook: 0.8,
                    reverse: false
                })
                .setTween(tlVueGsap2)
            this.$scrollmagic.addScene(scene2);
            const scene3 = this.$scrollmagic
                .scene({
                    triggerElement: ".about-page__body h2",
                    triggerHook: 0.7,
                    reverse: false
                })
                .on('start', function () {
                    setTimeout(() => {
                        self.play = true;
                    }, 1500);
                })
            this.$scrollmagic.addScene(scene3);
            const scene4 = this.$scrollmagic
                .scene({
                    triggerElement: ".about-page__img",
                    triggerHook: 0.8,
                    reverse: false
                })
                .on('start', function () {
                    setTimeout(() => {
                        self.play2 = true;
                    }, 1500);
                })
            this.$scrollmagic.addScene(scene4);
        }, 1000)
    },
    created() {
      this.requestVideo();
      this.requestTeam();
        this.breadcrumbs = [
            {
                name: 'Главная',
                url: '/'
            },
            {
                name: 'О компании',
            }
        ]
        this.statistics = [
            {
                num: '9 лет',
                ending: " лет",
                timing: 2,
                text: 'работы'
            },
            {
                num: '60+',
                ending: "+",
                timing: 2,
                text: 'проектов реализованно'
            },
            {
                num: '100%',
                ending: "%",
                timing: 2,
                text: 'завершенных проектов'
            }

        ]
        this.statisticsTwo = [
            {
                num: '15',
                ending: "",
                timing: 2,
                text: 'ИТР'
            },
            {
                num: '150+',
                ending: "+",
                timing: 2,
                text: 'прорабов'
            },
            {
                num: '17',
                ending: "",
                timing: 2,
                text: 'бригад'
            }

        ]
    },
    methods: {
        ...mapMutations(['setActivePage']),
        ...mapActions(['requestTeam', 'requestVideo']),
        playVideo() {
            this.$refs.video.play()
            this.isPlaying = true
        },
        stop() {
            this.$refs.video.pause()
            this.isPlaying = false
        }
    }
}
</script>

<style scoped>

</style>
