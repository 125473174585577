<template lang="pug">
  .intro-block.pt
   .container
     .intro-block__wrap
       .intro-block__left-side
        router-link(v-if="link.matched.length" :to='link.path').intro-block__back Назад
        router-link(v-if="!link.matched.length" to='/').intro-block__back Главная
        .intro-block__title
          h1.title-h1 {{title}}
       .intro-block__right-side
         .intro-block__img(:style="'background-image: url(' + require(`@/assets/imgs/${url}`) + ')'")

</template>

<script>
export default {
name: "IntroBlock",
  props: [
      'url', 'title', 'link'
  ],
  data() {
    return {}
  },
  mounted() {
    setTimeout(() => {
      const tlVueGsap2 = new TimelineMax()
          .to('.intro-block', 1, {
              paddingBottom: '0px',
              delay: 0,
              ease: "Power2.easeOut"
          })
          .from('.intro-block h1', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
          .from('.intro-block__right-side', 1, {
            y: 100,
            opacity: 0,
            delay: -0.5,
            ease: "Power2.easeOut"
          })
      const scene1 = this.$scrollmagic
          .scene({
            triggerElement: ".intro-block",
            triggerHook: 0.8,
            reverse: false
          })
          .setTween(tlVueGsap2)
      this.$scrollmagic.addScene(scene1);
    }, 200);
  }

}
</script>

<style scoped>

</style>