import axios from "axios";
import store from "@/store";

let state = {
servicesCategories: [],
services: [
    {
        num: '01',
        title1: 'Расчет инвестиционного',
        title2: 'проекта и подбор best use, проектирование',
        img: 'service-1-background.png',
        listTitle: 'Этапы расчеты проекта:',
        content: [
            {
                type: 'list',
                list: [
                    {
                        text: 'Экспертиза рынка недвижимости (тренды, динамика, законодательство) перспективе сроков реализации проекта;'
                    },
                    {
                        text: 'Разработка инвестиционного замысла, оценка потенциала доходности будущего проекта',
                        menu: [
                            'Укрупненные финансовые модели проекта и управление рисками;',
                            'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                            'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                            'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
                        ]
                    },
                    {
                        text: 'Разработка обоснования инвестиций, оценка жизнеспособности проекта, предварительное инвестиционное решение',
                        menu: [
                            'Укрупненные финансовые модели проекта и управление рисками',
                            'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                            'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                            'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
                        ]
                    },
                    {
                        text: 'Подбор проекта из существующих на рынке под инвестора, под заданную норму доходности;'
                    },
                    {
                        text: 'Создание инвестпроекта для эффективной реализации площадки (презентация для инвестора).'
                    }
                ],
            },

        ],
        list: [
            {
                text: 'Экспертиза рынка недвижимости (тренды, динамика, законодательство) перспективе сроков реализации проекта;'
            },
            {
                text: 'Разработка инвестиционного замысла, оценка потенциала доходности будущего проекта',
                menu: [
                    'Укрупненные финансовые модели проекта и управление рисками;',
                    'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                    'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                    'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
                ]
            },
            {
                text: 'Разработка обоснования инвестиций, оценка жизнеспособности проекта, предварительное инвестиционное решение',
                menu: [
                    'Укрупненные финансовые модели проекта и управление рисками',
                    'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                    'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                    'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
                ]
            },
            {
                text: 'Подбор проекта из существующих на рынке под инвестора, под заданную норму доходности;'
            },
            {
                text: 'Создание инвестпроекта для эффективной реализации площадки (презентация для инвестора).'
            }
        ],
        statisticsOne: [],
        statisticsTwo: [],
        simpleText: '',
        simpleTextTwo: '',
        slider: {
            title: '',
            slides: []
        },
        gallery: []
    },
],
serviceOne: {
    num: '01',
    title1: 'Расчет инвестиционного',
    title2: 'проекта и подбор best use, проектирование',
    imgDesc: '',
    img: 'service-1-background.png',
    listTitle: 'Этапы расчеты проекта:',
    list: [
        {
            text: 'Экспертиза рынка недвижимости (тренды, динамика, законодательство) перспективе сроков реализации проекта;'
        },
        {
            text: 'Разработка инвестиционного замысла, оценка потенциала доходности будущего проекта',
            menu: [
                'Укрупненные финансовые модели проекта и управление рисками;',
                'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
            ]
        },
        {
            text: 'Разработка обоснования инвестиций, оценка жизнеспособности проекта, предварительное инвестиционное решение',
            menu: [
                'Укрупненные финансовые модели проекта и управление рисками',
                'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
            ]
        },
        {
            text: 'Подбор проекта из существующих на рынке под инвестора, под заданную норму доходности;'
        },
        {
          text: 'Создание инвестпроекта для эффективной реализации площадки (презентация для инвестора).'
        }
    ],
    statisticsOne: [],
    statisticsTwo: [],
    simpleText: '',
    simpleTextTwo: '',
    slider: {
        title: '',
        slides: []
    },
    gallery: []
},


serviceTwo: {
    num: '02',
    title1: 'Капитальное строительство, ',
    title2: 'реконструкция и сдача в эксплуатацию',
    img: 'service-2-background.png',
    imgDesc: '«Аэронавигация Северо-Запада» г. Санкт-Петербург, 10-я линия Васильевского острова, 53 «Доходный дом Фрикке»',
    listTitle: 'Этапы работ на стадии вхождения:',
    list: [
        {
            text: 'Оценка инвестиционного потенциала актива'
        },
        {
            text: 'Оценка экономики будущего проекта'
        },
        {
            text: 'Аудит проектной и рабочей документации',
            menu: [
                'Укрупненные финансовые модели проекта и управление рисками;',
                'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
            ]
        },
        {
            text: 'Реконцепция проекта, повышение маржинальности проекта'
        },
        {
            text: 'Оценка объема инвестиций и рентабельности'
        },
        {
            text: 'Оценка перспектив проектного финансирования'
        },
        {
            text: 'Корректировка плана продаж и строительства, бюджета доходов и расходов, фазирование'
        },
        {
            text: 'Корректировка финансовой модели: показатели эффективности, объем собственных средств'
        },
        {
            text: 'Подготовка коммерческого задания для проектирования: коррекция позиционирования, описания продукта и УТП, квартирографии, ТЭПов'
        }
    ],
    statisticsOne: [],
    statisticsTwo: [],
    simpleText: '',
    simpleTextTwo: '',
    slider: {
        title: '',
        slides: []
    },
    gallery: []
},


serviceThree: {
    num: '03',
    title1: 'Металлоконструкции',
    title2: '',
    img: 'service-3-background.png',
    imgDesc: '',
    listTitle: 'Инвестиционно-строительная стадия:',
    list: [
        {
            text: 'Разработка подготовка проектно-сметной документации, планирование',
            menu: [
                'Укрупненные финансовые модели проекта и управление рисками;',
                'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
            ]
        },
        {
            text: 'Реализация строительно-монтажных работ',
            menu: [
                'Укрупненные финансовые модели проекта и управление рисками;',
                'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
            ]
        },
        {
            text: 'Сложные инженерные решения',
            menu: [
                'Укрупненные финансовые модели проекта и управление рисками;',
                'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
            ]
        },
        {
            text: 'Монетизация',
            menu: [
                'Укрупненные финансовые модели проекта и управление рисками;',
                'Укрупненный расчет объема необходимых инвестиций (стоимости строительства и прочих затрат);',
                'Построение финансовой модели проекта: P&L, Cash Flow (CF), Balance Sheet (BS);',
                'Расчет финансовой эффективности проекта (ставка дисконтирования, IRR, NPV и т.д.);'
            ]
        },
        ],
    statisticsOne: [],
    statisticsTwo: [],
    simpleText: '',
    simpleTextTwo: '',
    slider: {
        title: '',
        slides: []
    },
    gallery: []
},


serviceFour: {
    num: '04',
    title1: 'Инженерное переоснащение',
    title2: '',
    img: 'service-4-background.png',
    imgDesc: 'ИТП АО «СО ЕЭС»',
    listTitle: 'Как мёртвый груз превратить в источник дохода:',
    list: [
        {
            text: 'Ввод объектов в эксплуатацию: получение ЗОС и РНВ, заселение и передача УК;'
        },
        {
            text: 'Создание УК, регистрация, лицензирование, отладка бизнес-процессов, передача заказчику действующей организации, заключение договоров с ресурсоснабжающими органзациями'
        }
        ],
    statisticsOne: [
        {
            num: "38000/м<sup>3</sup>",
            ending: '/м3',
            text: "покупка",
            timing: 2
        },
        {
            num: "40000/м<sup>3</sup>",
            ending: '/м3',
            text: "редевелопмент",
            timing: 2
        },
        {
            num: "150000p./м<sup>3</sup>",
            ending: '/м3',
            text: "продажная цена",
            timing: 2
        }
    ],
    statisticsTwo: [
        {
            num: "38",
            text: "стоимость объекта",
            ending: ' млн.руб.',
            timing: 2
        }
    ],
    simpleText: '<b>Исходное состояние:</b>\n' +
        '      <p>По итогам оценки состояния объекта, проверки включенности здания в программу капитального ремонта жилого фонда Санкт-Петербурга по разделам: фасад, кровля и оценки вложений в реконструкцию (изыскания, проектирование, согласование, ремонтные работы, подключение к ресурсам, сдача в эксплуатацию).</p>',
    simpleTextTwo: '<b> Состояние объекта:</b>\n' +
        '      <p> Аварийные перекрытия, перегородки, инженерные сети, кровля, фасад.</p>',
    slider: {
        title: 'Объект культурного наследия регионального значения «Калинкинский морской госпиталь»',
        slides: [
            {
                desktop: 'slide_one.png',
                mobile: 'img-mobile.png'
            },
            {
                desktop: 'slide_one.png',
                mobile: 'img-mobile.png'
            },
            {
                desktop: 'slide_one.png',
                mobile: 'img-mobile.png'
            }
        ]
    },
        gallery: [ 'service-img_1.png', 'service-img_2.png', 'service-img_3.png']
    }
};

let mutations = {
    SetServices(state, api) {
        state.services = api.response.data;
        state.services.sort((elemA, elemB) => elemA.id - elemB.id);
        state.servicesCategories.splice(0, state.servicesCategories.length); // при переходах на другую стр и возврату на стр услуги удаляем массив, чтобы не было повторного пуша категорий

        for (let i = 0; i <= state.services.length; i++) {
            state.servicesCategories.push({
                name: state.services[i].category_name,
                id: i+1
            });
            state.services[i].title1 = state.services[i].category_name.split(' ').splice(0, Math.floor(state.services[i].category_name.split(' ').length/2)).join(' ');
            state.services[i].title2 = state.services[i].category_name.split(' ').splice(Math.floor(state.services[i].category_name.split(' ').length/2), state.services[i].category_name.split(' ').length-Math.floor(state.services[i].category_name.split(' ').length/2)).join(' ');
        }

        state.services.forEach(item => {
            item.specification.timing = 2
        })
    }
}

let actions = {
    getServicesFromApi({commit}) {
        axios
            .get(`${store.state.base_url}service`)
            .then(response => {
                commit('SetServices', response.data)
            })
            .catch(error => error)
    }
}

let getters = {
    GetServices: state => state.services,

    GetCategories: state => state.servicesCategories,
}



export default {
    actions,
    getters,
    mutations,
    state,
};