<template lang="pug">
  .wrap
    sidebar(:activeItem="activeItem" :num="allBlock")
    intro(ref="intro" id="1" :data="getHomePage" :url="getRootUrl").main-page-block
    redevelopment(id="2").main-page-block
    invite(id="3" :data="getHomePage" :url="getRootUrl").main-page-block
    project(id="4").main-page-block
    Offer(id="5" :photo="getHomePage.wantTheSameDesktopPhoto" :url="getRootUrl").main-page-block
    team(id="6" :team="getTeam" :url="getRootUrl").main-page-block
    license(id="7").main-page-block
    contacts(id="8" :team="getTeam" :url="getRootUrl").main-page-block
</template>

<script>

import Intro from "@/components/Intro";
import Redevelopment from "@/components/Redevelopment";
import Invite from "@/components/Invite";
import Project from "@/components/Project";
import Team from "@/components/Team";
import Contacts from "@/components/oneProject/Contacts";
import License from "@/components/License";
import Offer from "@/components/Offer";
import Sidebar from "@/components/main/Sidebar";
import { mapActions, mapMutations, mapGetters } from "vuex";

import * as ScrollMagic from "scrollmagic";
import { gsap } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Engineering from "@/components/Engineering";

// ScrollMagicPluginGsap(ScrollMagic, gsap);

export default {
  name: "Home",
  components: {
    Engineering,
    Sidebar,
    Offer,
    License,
    Contacts,
    Team,
    Project,
    Invite,
    Redevelopment,
    Intro
  },
  data: () => ({
    activeItem: 1,
    // количество блоков. если нужно добавить еще один блок, то добавь число в массив ниже и анимацию в функции Scroll Magic
    allBlock: [1, 2, 3, 4, 5, 6, 7, 8],

    // intersectionOptions: {
    //   root: null,
    //   rootMargin: '0px 0px 0px 0px',
    //   threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
    // },
    // intersectionOptions1: {
    //   root: null,
    //   rootMargin: '0px 0px 0px 0px',
    //   threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
    // }
  }),
  computed: {
    ...mapGetters(['getTeam', 'getRootUrl', 'getHomePage'])
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['requestTeam', 'requestHomePage']),
    ...mapMutations(['setActivePage']),
    handleScroll() {

      //console.log(window.pageYOffset)

      if (window.pageYOffset > 100) {
        document.querySelector('header').classList.add('fixed')
      }
      if (window.pageYOffset === this.$refs.intro.$el.pageYOffset) {
        this.activeItem = 2;
      }
      // Any code to be executed when the window is scrolled
    }
  },
  mounted() {
    this.requestTeam();
    this.requestHomePage();

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: document.getElementById(this.$route.hash.split('#').join('')).offsetTop - 260,
        behavior: 'smooth'
      })
    }, 1000)

    // setTimeout(() => {
    //   const allBlocks = document.querySelectorAll('.main-page-block');
    //   allBlocks.forEach(block => {
    //     this.allBlock.push(block.getAttribute('id'));
    //   })
    // }, 600)

    this.setActivePage('Главная');
    let self = this;
    setTimeout(() => {
      // create a scene
      const tlVueGsap = new TimelineMax()
          .from('.intro__title', 1.5, {opacity: 0, y: 100, delay: 1, ease: "Power2.easeOut"})
          .from('.intro__info', 1, {opacity: 0, y: 100, delay: -1.2, ease: "Power2.easeOut"})
      const scene1 = this.$scrollmagic
          .scene({
            triggerElement: ".intro",
            triggerHook: 0.3,
            reverse: false
          })
          .setTween(tlVueGsap)
      this.$scrollmagic.addScene(scene1);

      const tlVueGsap2 = new TimelineMax()
          .to('.intro', 1, {
              paddingBottom: '0px',
              delay: 0,
              ease: "Power2.easeOut",
              reverse: false
          })
          .from('.intro__img', 1, {opacity: 0, y: 100, delay: 1, ease: "Power2.easeOut"})
          .from('.intro__services', 1, {opacity: 0, y: 100, delay: -0.5, ease: "Power2.easeOut"})
          .from('.intro-bg', 1, {y: 200, delay: -0.2, ease: "Power2.easeOut"})
      const scene2 = this.$scrollmagic
          .scene({
            triggerElement: ".intro",
            triggerHook: 0.3,
            reverse: false
          })
          .setTween(tlVueGsap2)
      this.$scrollmagic.addScene(scene2);

      const tlVueGsap3 = new TimelineMax().from('.project__left-side', 1, {opacity: 0, y: 200, ease: "Power2.easeOut"})
          .from('.project__right-side', 1, {opacity: 0, y: 200, delay: -0.7, ease: "Power2.easeOut"})
          .from('.project__bottom', 1, {opacity: 0, y: 200, delay: -0.5, ease: "Power2.easeOut"})
      const scene3 = this.$scrollmagic
          .scene({
            triggerElement: ".project",
            triggerHook: 0.3,
            reverse: false
          })
          .setTween(tlVueGsap3)
      this.$scrollmagic.addScene(scene3);



        const scene6 = this.$scrollmagic
            .scene({
              triggerElement: ".redevelop__title",
              triggerHook: 0.5,
            })
            .on('start', function () {
              self.activeItem = 2;
            })

        this.$scrollmagic.addScene(scene6);

      const scene16 = this.$scrollmagic
          .scene({
            triggerElement: ".redevelop__right-side .statistics",
            triggerHook: 0.4,
          })
          .on('start', function () {
            self.activeItem = 2;
          })

      this.$scrollmagic.addScene(scene16);

      const scene7 = this.$scrollmagic
          .scene({
            triggerElement: ".invite__left-side h2",
            triggerHook: 0.5,
          })
          .on('start', function () {
            self.activeItem = 3;
          })

      this.$scrollmagic.addScene(scene7);

      const scene17 = this.$scrollmagic
          .scene({
            triggerElement: ".invite__img",
            triggerHook: 0,
          })
          .on('start', function () {
            self.activeItem = 3;
          })

      this.$scrollmagic.addScene(scene17);

      const scene8 = this.$scrollmagic
          .scene({
            triggerElement: ".project__left-side h2",
            triggerHook: 0.5,
          })
          .on('start', function () {
            self.activeItem = 4;
          })

      this.$scrollmagic.addScene(scene8);

      const scene18 = this.$scrollmagic
          .scene({
            triggerElement: ".project__bottom",
            triggerHook: 0.3,
          })
          .on('start', function () {
            self.activeItem = 4;
          })

      this.$scrollmagic.addScene(scene18);

      const scene9 = this.$scrollmagic
          .scene({
            triggerElement: ".offer__form h2",
            triggerHook: 0.5,
          })
          .on('start', function () {
            self.activeItem = 5;
          })

      this.$scrollmagic.addScene(scene9);

      const scene19 = this.$scrollmagic
          .scene({
            triggerElement: ".offer__form .input-checkbox",
            triggerHook: 0.5,
          })
          .on('start', function () {
            self.activeItem = 5;
          })

      this.$scrollmagic.addScene(scene19);

      const scene10 = this.$scrollmagic
          .scene({
            triggerElement: ".team h2",
            triggerHook: 0.5
          })
          .on('start', function () {
            self.activeItem = 6;
          })

      this.$scrollmagic.addScene(scene10);

      const scene20 = this.$scrollmagic
          .scene({
            triggerElement: ".team__wrap",
            triggerHook: 0.1,
          })
          .on('start', function () {
            self.activeItem = 6;
          })

      this.$scrollmagic.addScene(scene20);

      const scene21 = this.$scrollmagic
          .scene({
            triggerElement: ".intro__img img",
            triggerHook: 0.2,
          })
          .on('start', function () {
            self.activeItem = 1;
          })

      this.$scrollmagic.addScene(scene21);

      const scene11 = this.$scrollmagic
          .scene({
            triggerElement: ".license__info h2",
            triggerHook: 0.6,
          })
          .on('start', function () {
            self.activeItem = 7;

          })

      this.$scrollmagic.addScene(scene11);

        const scene15 = this.$scrollmagic
            .scene({
                triggerElement: ".swiper-pagination-license",
                triggerHook: 0.6,
            })
            .on('start', function () {
                self.activeItem = 7;

            })

        this.$scrollmagic.addScene(scene15);

        const scene13 = this.$scrollmagic
            .scene({
                triggerElement: ".contacts__info h2",
                triggerHook: 0.6,
            })
            .on('start', function () {
                self.activeItem = 8;

            })

        this.$scrollmagic.addScene(scene13);

        const scene12 = this.$scrollmagic
            .scene({
                triggerElement: ".footer",
                triggerHook: 0.7,
            })
            .setTween('.pages', 0.4, {opacity: 0})

        this.$scrollmagic.addScene(scene12);


    }, 1600)
  }


};
</script>
