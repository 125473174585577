<template lang="pug">
  section.project
    .container.pl
      .project__wrap(v-if="getMainPageProject")
        .project__left-side
          h2 {{ getMainPageProject.name }}
          .project__text
            p {{ getMainPageProject.result.value }}
            //p В ходе проекта по редевелопменту был разработан и согласован проект реконструкции объекта культурного наследия и приспособления его под современное использование. Разработан дизайн проект.

            vue-aos(animation-class="fadeIn animated")
              RoundButton(text="Смотреть все объекты" color="white" link="/portfolio")

        .project__right-side
          BigStatistics(:statistics-list="statisticsList")

          swiper(ref="mySwiper" :options="swiperOptions" v-if="getMainPageProject.slider")
            swiper-slide(v-for="(photo, id) in getMainPageProject.slider" :key="id")
              .projects__img-wrap
                img(:src="getRootUrl + photo.file_path" alt="")

          .swiper-pagination.swiper-pagination-desktop(slot="pagination")


          .swiper__navigation
            .swiper-button-prev.project-btn-prev(slot="button-prev")
            .swiper-button-next.project-btn-next(slot="button-next")

          SwiperMobile(v-if="getMainPageProject.slider" :photos="getMainPageProject.slider" :url="getRootUrl")


      .project__bottom(v-if="getMainPageProject")
        BigStatistics(:statistics-list="getMainPageProject.specification")

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import VueAos from 'vue-aos'
//import 'swiper/swiper-bundle.css'
import RoundButton from "@/components/main/RoundButton";
import BigStatistics from "@/components/BigStatistics";
import { mapState, mapGetters } from "vuex";
import SwiperMobile from "@/components/SwiperMobile";

export default {
name: "Project",
  components: {
    SwiperMobile,
    BigStatistics,
    RoundButton,
    Swiper,
    SwiperSlide,
    VueAos},
  data() {
    return {
      initialSlide: 0,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        direction: 'horizontal',
        speed: 900,
        pagination: {
          el: '.swiper-pagination-desktop',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.project-btn-next',
          prevEl: '.project-btn-prev'
        },
      },
    }
  },
  computed: {
    ...mapGetters(['getMainPageProject', 'getRootUrl']),
    ...mapState({
      statisticsList: (state) => state.statistics.projectStatistic,
      BigstatisticsList: (state) => state.statistics.bigProjectStatistic,
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    this.swiper.init();
  }
}
</script>

<style scoped>

</style>