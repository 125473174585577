<template lang="pug">
  .service-slider
    h3 {{ServiceInfo.slider.title}}
    swiper(ref="mySwiperService" :options="swiperServiceOptions")
      swiper-slide(v-for="item in ServiceInfo.slider.slides")
        .projects__img-wrap
          img(:src="require(`@/assets/imgs/${item.desktop}`)" alt="")
          img.projects__img-mobile(:src="require(`@/assets/imgs/${item.mobile}`)" alt="")

    .swiper-pagination-service(slot="pagination")

    .swiper__navigation
      .swiper-button-prev.service-swiper-btn-prev(slot="button-prev")
      .swiper-button-next.service-swiper-btn-next(slot="button-next")

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: "ServiceItemSlider",
    components: {
    Swiper,
    SwiperSlide
  },
  props: {
    ServiceInfo: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  data() {
    return {
      swiperServiceOptions: {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 10,
        speed: 900,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination-service',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.service-swiper-btn-next',
          prevEl: '.service-swiper-btn-prev'
        },
        breakpoints: {
          // when window width is >= 320px
          768: {
            slidesPerView: 1,
            pagination: {
              el: '.swiper-pagination-service',
              type: 'fraction'
            },
          },
          320: {
            slidesPerView: 3,
            centeredSlides: true,
            pagination: {
              el: '.swiper-pagination-service',
              type: 'bullets'
            },
          }
        }
      },
    }
  },
    computed: {

      swiper() {
        return this.$refs.mySwiperService.$swiper
      }
    },
    mounted() {
      this.swiper.init();
    }

}
</script>

<style scoped>

</style>