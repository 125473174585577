<template lang="pug">
  a.button(:class="[addClass, 'button--' + size]" @click="$emit('click')") {{ text }}
</template>

<script>
export default {
name: "Button",
  props: [
      'text', 'addClass', 'size'
  ]
}
</script>

<style scoped>

</style>