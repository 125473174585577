<template lang="pug">
  section.offer(v-if="url")
    .container.pl
      .offer__wrap
        .offer__form
          h2
            span Хотите
            span так же?
          offer-form-block
        .offer__img
          img(:src="url + photo" alt="")
</template>

<script>
import FormBlock from "@/components/FormBlock";
import OfferFormBlock from "@/components/OfferFormBlock";
export default {
name: "Offer",
  components: {OfferFormBlock, FormBlock},
  props: {
    photo: {
      type: String
    },
    url: {
      type: String
    }
  },
  mounted() {
    let self = this;
    // setTimeout(() => {
    //   const tlVueGsap = new TimelineMax()
    //       .from('.offer__form h2 span:first-child', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
    //       .from('.offer__form h2 span:last-child', 1, {
    //         y: 100,
    //         rotation: "8deg",
    //         opacity: 0,
    //         delay: -0.5,
    //         ease: "Power2.easeOut"
    //       })
    //       .from('.offer__img img', 1, {y: 800, opacity: 0, delay: -0.5, ease: "Power2.easeOut"})
    //   const scene4 = this.$scrollmagic
    //       .scene({
    //         triggerElement: ".offer",
    //         triggerHook: 0.5,
    //       })
    //       .setTween(tlVueGsap)
    //
    //   this.$scrollmagic.addScene(scene4);
    //
    // }, 200)
  }
}
</script>

<style scoped>

</style>
