<template lang="pug">
    .modal(@click="closeModal($event)")
        .modal__wrap(v-if="!success")
            .modal__close(@click="setModalStatus(false)")
                img(src="@/assets/imgs/close-modal.svg")
            .modal__top
                h2.modal__title Оставить заявку
                p.modal__text В ближайшее время с вами свяжется наш специалист. Также, вы можете пригласить нашу компанию в тендер и/или отправить техническое задание.
            form.modal__form(@submit.prevent="sendData")
                .form__row
                    input-block(
                        @focus="$v.form.userName.$reset()"
                        id="name"
                        placeholder="Введите имя"
                        label="Имя"
                        @inputChange="changeInput($event, 'userName')"
                        :error="$v.form.userName.$error"
                        :messageEmpty="($v.form.userName.$dirty && !$v.form.userName.required) ? 'заполните поле' : null"
                    )
                .form__row
                    .input-block(:class="{error: $v.form.tel.$error}")
                        label(for="tel") Телефон
                        input(type="tel"
                            id="tel"
                            v-mask="'+7 (999) 999-99-99'"
                            focus="$v.form.tel.$reset()"
                            v-model="form.tel"
                            placeholder="+7 (___) ___-__-__"
                        )

                        span.input-block__error(v-if="($v.form.tel.$dirty && !$v.form.tel.required) ? 'заполните поле' : null") заполните поле
                        span.input-block__error(v-if="form.tel.replace(/[^+\\d]/g, '').length < 12 && form.tel.replace(/[^+\\d]/g, '').length > 0") заполните номер полностью
                .form__row
                    input-block(
                        id="comment"
                        placeholder="Введите текст"
                        label="Комментарий"
                        @inputChange="changeInput($event, 'comment')"
                    )


                p.add-file-name(v-if="imgShare.length") <span class="add-file-success"></span> {{ fileName }}

                .add-file
                    input(type="file" id="addFile" @change="uploadImage($event)")
                    label(for="addFile") Прикрепить файл


                button(type="submit").button.button--medium Отправить

                .form__row
                    checkbox(
                        id="policyModal"
                        @changeCheckbox="changeCheckbox($event)"
                        :error="$v.form.checkbox.$error"
                    )

        .modal__wrap.modal-success(v-if="success")
            .modal__close(@click="setModalStatus(false)")
                img(src="@/assets/imgs/close-modal.svg")
            p.modal-success__title Заявка оформлена

            p.modal-success__text Ожидайте, в ближайшее время с вами свяжется наш специалист.

            .modal-success__img
              img(src="@/assets/imgs/project_img.png" alt="")



</template>

<script>
import MaskedInput from "vue-masked-input/src/MaskedInput";
import InputBlock from "@/components/InputBlock";
import InputTel from "@/components/InputTel";
import Button from "@/components/main/Button";
import Checkbox from "@/components/Checkbox";
import AddFile from "@/components/addFile";
import { required, email } from 'vuelidate/lib/validators';
import {mapMutations, mapActions, mapGetters} from "vuex";


function telValidator (value) {
    return !(value.replace(/[^+\d]/g, '').length < 12)
}

export default {
    name: "Modal",
    components: {
        AddFile, Checkbox, Button, InputTel, InputBlock, MaskedInput
    },
    data() {
        return {
            imgShare: '',
            fileName: '',
            success: false,
            form: {
                comment: '',
                userName: "",
                email: "",
                tel: "",
                checkbox: true
            },
            myForm: new FormData()
        }
    },
    validations: {
        form: {
            userName: {
                required
            },
            tel: {
                required,
                telValidator
            },
            checkbox: {
                checked (val) {
                    return val
                }
            }
        }
    },
    props: ['withoutFile'],
    methods: {
        ...mapMutations(["setModalStatus"]),
        ...mapActions(["sendForm"]),

        uploadImage(event) {
          let file = event.target.files[0];
          this.imgShare = URL.createObjectURL(file);
          this.fileName = file.name;
          this.imageFile = file
        },
        changeCheckbox(value) {
            this.form.checkbox = value;
        },
        changeInput(value, input) {
            this.form[input] = value;
        },
        sendData() {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            this.myForm.append('file', this.imageFile)
            this.myForm.append('userName', this.form.userName)
            this.myForm.append('email', this.form.email)
            this.myForm.append('tel', this.form.tel)
            this.myForm.append('comment', this.form.comment)
            this.myForm.append('url', this.getUrl)

            this.sendForm(this.myForm);
            this.success = true;

            // setTimeout(() => {
            //   this.success = false
            // }, 2000);

            setTimeout(() => {
              this.comment = ''
              this.userName = ''
              this.email = ''
              this.url = ''
              this.tel = ''
              this.$v.$invalid = true
            })
          }
        },

        closeModal(e) {
          if (e.target.classList.contains('modal')) {
            this.setModalStatus(false)
          }
        },
    },
  computed: {
    ...mapGetters(['getUrl'])
  }
}
</script>

<style scoped>

</style>
