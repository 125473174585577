<template lang="pug">
  div.article
    .article__title-wrap
        h1.article__title {{title}}

    .article__address-wrap
      p.article__address {{address}}

    .article__body
      .article__wrap
        span.article__date {{month}}, {{year}}
        .article__img(v-if="img")
          img(:src="getRootUrl + img" alt="")


      .article__info
        slot


</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Article",
  props: ["title", "img", "month", "year", "address"],
  computed: mapGetters(['getRootUrl'])
}
</script>

<style scoped>

</style>