import axios from "axios";
import store from '../index'

let state = {
    contactsInfo: []
}

let mutations = {
    setContactsInfo(state, info) {
        state.contactsInfo = info
    }
}

let getters = {
    getContactInfo: state => state.contactsInfo[0]
}

let actions = {
    requestContactsInfo({commit, state}) {
        axios
            .get(`${store.state.base_url}contacts`, {
            headers: store.state.headers
            })
            .then(({ data }) => {
                if (data.response.status === 'success') {
                    commit('setContactsInfo', data.response.data)
                } else {
                    console.log('Contacts info error')
                }
            })
            .catch(e => console.log(e))
    }
}

export default {
    actions,
    state,
    mutations,
    getters
}
