<template lang="pug">
  section.team(id="team" v-if="team" )
    .container.pl
      h2
        span Команда
        span Grade
      .team__wrap
        swiper(ref="mySwiperTeam" :options="swiperOptions")
          swiper-slide(v-for="(employee, id) in team" :key="id")
            .team__slide
              img(:src="url + employee.desktop_photo" height='100%' width='100%')
              .team__info
                p.team__name {{ employee.name }}
                p.team__position {{ employee.profession }}

        .swiper-pagination-team(slot="pagination")
        .swiper-pagination-mobile-team(slot="pagination")

        .swiper__navigation
          .swiper-button-prev.team-swiper-btn-prev(slot="button-prev")
          .swiper-button-next.team-swiper-btn-next(slot="button-next")


</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
//import 'swiper/swiper-bundle.css'
import {mapState} from "vuex";

export default {
  name: "Team",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        speed: 900,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination-team',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.team-swiper-btn-next',
          prevEl: '.team-swiper-btn-prev'
        },

        breakpoints: {
          768: {

          },
          320: {
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            spaceBetween: 10,
            pagination: {
              el: '.swiper-pagination-mobile-team',
              type: 'bullets'
            },
          }
        }
      },
    }
  },
  props: {
    team: {
      type: Array,
      default: []
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({}),
    swiper() {
      return this.$refs.mySwiperTeam.$swiper
    }
  },
  mounted() {
    this.swiper.init();
    let self = this;
    // setTimeout(() => {
    //   const tlVueGsap = new TimelineMax()
    //       .from('.team h2 span:first-child', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
    //       .from('.team h2 span:last-child', 1, {
    //         y: 100,
    //         rotation: "8deg",
    //         opacity: 0,
    //         delay: -0.5,
    //         ease: "Power2.easeOut"
    //       })
    //       .from('.team__wrap .swiper-container', 1, {y: 800, opacity: 0, delay: -0.5, ease: "Power2.easeOut"})
    //   const scene4 = this.$scrollmagic
    //       .scene({
    //         triggerElement: ".team",
    //         triggerHook: 0.5,
    //       })
    //       .setTween(tlVueGsap)
    //
    //   this.$scrollmagic.addScene(scene4);
    //
    // }, 200)
  }

}
</script>

<style scoped>

</style>
