<template lang="pug">
  ul.statistics(:class="addClass")
    li(v-for="(item, i) in statisticsList" :key="'item' + i")
      p.statistics__num
        number(:ref="'item' + i" :from="numberFrom" :format="item.theFormat" animationPaused :to="item.value" :duration="item.timing" easing="Power1.easeOut")
        template(v-if="item.ending") {{item.ending}}
      p(v-html="item.name").desk-desc
      p(v-html="item.name.split(' ')[0]").mob-desc

</template>

<script>
export default {
name: "Statistics",
  data() {
    return {
      numberFrom: 0,
    }
  },
  watch: {
    trigger(newVal) {
      if (newVal) {
        for (let key in this.$refs) {
          this.$refs[key][0].play();
        }
      }
    }
  },
  props: {
    statisticsList: {
      type: Array,
      default() {
        return [];
      },
    },
    trigger: {
      type: Boolean,
      default: false
    },
    addClass: {
      type: String,
      default: ''
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
