<template lang="pug">
  section.contact-form.contact-form--black
    form.form(@submit.prevent="sendData")

      .contact-form__wrap
        .contact-form__img(:style="'background-image: url(' + require(`@/assets/imgs/form-bg.png`) + ')'")

        .contact-form__right-side
          h2 Хотите так же?
          .form-success(v-if="success")
            p.form-success__title Заявка оформлена
            p.form-success__text Ожидайте, в ближайшее время с вами свяжется наш специалист.
          .form-wrap(v-if="!success")
            .contact-form__row
              div.input-block(:class="{error : $v.form.userName.$error}")
                label.contact-form__label(for="contactFormName") Имя
                input(v-model="form.userName" type="text" id="contactFormName"
                  @focus=""
                )
                span(v-if="($v.form.userName.$dirty && !$v.form.userName.required) ? 'заполните поле' : null").input-block__error заполните поле
            .contact-form__row(ref="telRow")
              div.input-block(:class="{error : $v.form.tel.$error}")
                label.contact-form__label(for="contactFormTel") Телефон
                input(type="tel"
                  v-mask="'+7 (999) 999-99-99'"
                  focus="$v.form.tel.$reset()"
                  v-model="form.tel"
                  placeholder="+7 (___) ___-__-__"
                  id="contactFormTel"
                )
                span(v-if="($v.form.tel.$dirty && !$v.form.tel.required) ? 'заполните поле' : null").input-block__error заполните поле
                span.input-block__error(v-if="form.tel.replace(/[^+\\d]/g, '').length < 12 && form.tel.replace(/[^+\\d]/g, '').length > 0") заполните номер полностью


            button.button.button--big(type="submit") Связаться с нами

            .contact-form__row
              checkbox(
                id="checkPolicy"
                @changeCheckbox="changeCheckbox($event)"
                :error="$v.form.checkbox.$error"
                )

</template>

<script>
import Button from "@/components/main/Button";
import Checkbox from "@/components/Checkbox";
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex'

function telValidator (value) {
  return !(value.replace(/[^+\d]/g, '').length < 12)
}


export default {
name: "FeedbackForm",
  components: {Checkbox, Button},
  data() {
    return {
      success: false,
      form: {
        comment: '',
        userName: "",
        email: "",
        tel: "",
        checkbox: true
      },
      myForm: new FormData()
    }
  },
  validations: {
    form: {
      userName: {
        required
      },
      tel: {
        required,
        telValidator
      },
      checkbox: {
        checked (val) {
          return val
        }
      }
    }
  },
  methods: {
    ...mapActions(['sendForm']),
    changeCheckbox(value) {
      this.form.checkbox = value;
    },
    changeInput(value, input) {
      this.form[input] = value;
    },
    sendData() {
      this.$v.$touch();

      if (!this.$v.$invalid) {

        this.myForm.append('file', this.imageFile)
        this.myForm.append('userName', this.form.userName)
        this.myForm.append('email', this.form.email)
        this.myForm.append('tel', this.form.tel)
        this.myForm.append('comment', this.form.comment)
        this.myForm.append('url', this.getUrl)
        this.success = true;
        this.sendForm(this.myForm);

        // setTimeout(() => {
        //   this.success = false
        // }, 2000)
      }
    }
  }
}
</script>

<style scoped>

</style>