<template lang="pug">
    .service-item
        .container
            .service-item__title
                h2
                    span {{ServiceInfo.title1}}
                    span {{ServiceInfo.title2}}
                .service-item__button-wrap
                    Button(
                        @click="setModalStatus(true)"
                        text="Заказать услугу"
                        size="big"
                        link="#"
                    )
            .service-item__body
                .service-item__num
                    span.service-item__current-num(v-if="GetServices.length < 10") 0{{ GetServices.length }}
                    span.service-item__current-num(v-else) {{ GetServices.length }}
                    span &nbsp-&nbsp
                    span.service-item__totla-num(v-if="ServiceInfo.id < 10" ) 0{{ ServiceInfo.id }}
                    span.service-item__totla-num(v-else) {{ ServiceInfo.id }}

                .service-item__img-wrap(v-if="project.image")
                    .service-item__img(:style="'background-image: url('+ getRootUrl + project.image + ')'")
                    //.service-item__img(:style="'background-image: url(' + require(`@/assets/imgs/${ServiceInfo.img}`) + ')'")
                    router-link(:to="'portfolio/' + project.link").service-item__project-name {{ project.title }}

                .service-item__text
                    slot


</template>

<script>
import Button from "@/components/main/Button";
import ServiceItemList from "@/components/Services/ServiceItemList";
import ServiceItemSlider from "@/components/Services/ServiceItemSlider";
import Statistics from "@/components/Statistics";
import RoundButton from "@/components/main/RoundButton";
import {mapGetters, mapMutations} from "vuex";


export default {
    name: "ServiceItem",
    components: {RoundButton, Statistics, ServiceItemSlider, ServiceItemList, Button},
    props: {
        ServiceInfo: {
            type: Object,
            default() {
                return {};
            },
        },
        CategoriesNum: {
          type: Number
        },
        project: {
          type: Object,
          default() {
            return {};
          },
        }
    },
    computed: {
      ...mapGetters(['getRootUrl', 'GetServices'])
    },
    methods: {
        ...mapMutations(["setModalStatus"])
    }
}
</script>

<style scoped>

</style>