import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Portfolio from "@/views/Portfolio";
import Services from "@/views/Services";
import OneProject from "@/views/OneProject";
import About from "@/views/About";
import Contacts from "@/views/Contacts";
import Page404 from "@/views/Page404";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Home,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/portfolio/:id",
    name: "OneProject",
    component: OneProject,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "*",
    name: "Page 404",
    component: Page404,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
