<template lang="pug">
  div.input-block(:class="{error: error}")
    label(:for="id") {{label}}
    input(@focus="$emit('focus')" type="text" :id="id" :placeholder="placeholder" @input="$emit('inputChange', $event.target.value)")
    span.input-block__error(v-if="messageEmpty") {{messageEmpty}}
    span.input-block__error(v-if="message2") {{message2}}
</template>

<script>
export default {
name: "InputBlock",
  props: [
      'id', 'placeholder', 'label', 'error', 'messageEmpty', 'message2'
  ]
}
</script>

<style scoped>

</style>