import axios from 'axios';

let state = {
    statisticsList: [
        {
            num: "9",
            timing: 2,
            text: "лет работы"
        },
        {
            num: "100",
            ending: "+",
            timing: 4,
            text: "проектов реализованно"
        },
        {
            num: "100",
            ending: "%",
            timing: 4,
            text: "завершенных проектов"
        }

    ],
    statisticsEngineering: [
        {
            num: "9",
            timing: 2,
            text: "лет работы"
        },
        {
            num: "60",
            ending: "+",
            timing: 4,
            text: "проектов реализованно"
        },
        {
            num: "100",
            ending: "%",
            timing: 4,
            text: "завершенных проектов"
        }

    ],
    statisticsListTwo: [
        {
            num: "20",
            ending: "",
            timing: 2,
            text: "ИТР"
        },
        {
            num: "130",
            ending: "+",
            timing: 4,
            text: "строителей"
        },
        {
            num: "17",
            ending: "",
            timing: 2,
            text: "бригад"
        }

    ],
    projectStatistic: [
        {
            num: '1500',
            ending: "р./м<sup>3</sup>",
            timing: 4,
            text: 'аренда в месяц'
        },{
            num: '60',
            ending: " месяцев",
            timing: 2,
            text: 'окупаемость'
        },
        {
            num: '20% в год',
            ending: "% в год",
            text: 'ROI',
            timing: 2,
        }

    ],
    bigProjectStatistic: [
        {
            num: '14 месяцев',
            text: 'длительность проекта'
        },
        {
            num: '40000р./м<sup>3</sup>',
            text: 'ремонт и оснащение'
        },
        {
            num: '90000р./м<sup>3</sup>',
            text: 'итоговая себестоимость объекта'
        },
        {
            num: '150000р./м<sup>3</sup>',
            text: 'рыночная стоимость'
        },
        {
            num: '12% в год',
            text: 'ROI'
        }
    ]
};

let mutations = {
    SetStatistics: (state, data) => {
        state.statistics = data
    }
}

let actions = {
    getStatisticsFromApi({commit, state}) {
        axios
            .get(`${store.state.base_url}/statistics`)
            .then(response => {
                commit('SetStatistics', response.data.response.data)
            })
            .catch(error => error)
    }
}


export default {
    state,
    actions,
    mutations
};