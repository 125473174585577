<template>
  <div ref="gallery" class="gallery-modal" @click="closeGallery($event)">
    <div
        class="cssload-spin-box"
        :class="{'visible' : !showGallery}"
    ></div>
    <button class="gallery-modal__prev">
      <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6195 0.990194C10.1295 0.500194 9.33952 0.500194 8.84952 0.990194L0.539523 9.30019C0.149523 9.6902 0.149523 10.3202 0.539523 10.7102L8.84952 19.0202C9.33952 19.5102 10.1295 19.5102 10.6195 19.0202C11.1095 18.5302 11.1095 17.7402 10.6195 17.2502L3.37952 10.0002L10.6295 2.75019C11.1095 2.2702 11.1095 1.47019 10.6195 0.990194Z" fill="#3E3E3E"/>
      </svg>
    </button>

    <div class="gallery-modal__slider" :class="{'visible' : showGallery}">
      <swiper :options="swiperOptions">
        <swiper-slide v-for="img in imgSrc">
          <img
            class="gallery-modal__img"
            :class="{'visible' : showGallery}"
            :src="'https://grade.company/' + img.file_path" alt="">
        </swiper-slide>
      </swiper>
    </div>
            <!--    <img-->
<!--        class="gallery-modal__img"-->
<!--        :class="{'visible' : showGallery}"-->
<!--        :src="require(`@/assets/imgs/${imgSrc[0]}`)" alt="">-->
    <button class="gallery-modal__next">
      <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.380479 19.0098C0.870479 19.4998 1.66048 19.4998 2.15048 19.0098L10.4605 10.6998C10.8505 10.3098 10.8505 9.6798 10.4605 9.2898L2.15048 0.979805C1.66048 0.489805 0.870479 0.489805 0.380479 0.979805C-0.109521 1.4698 -0.109521 2.2598 0.380479 2.7498L7.62048 9.9998L0.370478 17.2498C-0.109522 17.7298 -0.109521 18.5298 0.380479 19.0098Z" fill="#232323"/>
      </svg>
    </button>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: "GalleryModal",
  props: {
    imgSrc: Array,
    index: Number
  },
  data() {
    return {
      showGallery: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 4,
        direction: 'horizontal',
        initialSlide: this.index,
        speed: 900,
        navigation: {
          nextEl: '.gallery-modal__next',
          prevEl: '.gallery-modal__prev'
        },
      },
    }
  },
  components: {
    Swiper, SwiperSlide
  },
  created() {
    if (!this.showGallery) {
      setTimeout(() => {
        this.showGallery =  true
      }, 1000)
    }
  },
  methods : {
    closeGallery(event) {
      if(event.target === this.$refs.gallery) {
        this.$modal.hideAll()
      }
    }
  },
}

</script>