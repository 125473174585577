<template lang="pug">
  .contacts
    .container.pl
      .contacts__wrap
        .contacts__info
          h2
            span Контакты

          ul.contacts__list
            li.contacts__item.contacts__address
              p 190020, г. Санкт-Петербург, проспект Старо-Петергофский, д. 9А, литера А, пом. 42
            li.contacts__item.contacts__tel
              a(href="tel:+78126666776") +7-812-666-67-76


          .contacts__text
            p Объект культурного наследия регионального значения «Калинкинский морской госпиталь»

          ul.contacts__statistic
            //li.contacts__statistic-item
            //  p.contacts__statistic-num 12000 м2
            //  p.contacts__statistic-text общая площадь
            //li.contacts__statistic-item
            //  p.contacts__statistic-num 2012-2014
            //  p.contacts__statistic-text период работ


          Button(@click="setModalStatus(true)" text="Связаться с нами" size="big")




        .contacts__map
          #map
            yandex-map(
              :coords="$options.coords"
              zoom="13"
              style="width: 600px; height: 600px;"
              :cluster-options="{1: {clusterDisableClickZoom: true}}"
              :behaviors="['dblClickZoom', 'drag', 'multiTouch']"
              :controls="['zoomControl']"
              )
              ymap-marker(
                marker-type="placemark"
                marker-id="mainMap"
                :coords="$options.coords"
                :icon="markerIcon"
                cluster-name="1")
                //hint-content="Hint content 1"
                //:balloon="{header: 'header', body: 'body', footer: 'footer'}"


    .contacts__map.contacts__map-mobile
      #mapMobile
        yandex-map(
          :coords="$options.coords"
          zoom="13"
          style="width: 600px; height: 600px;"
          :cluster-options="{1: {clusterDisableClickZoom: true}}"
          :behaviors="['dblClickZoom']"
          :controls="['zoomControl', 'trafficControl']"
        )
          ymap-marker(
            marker-type="placemark"
            marker-id="mainMap"
            :coords="$options.coords"
            hint-content="Hint content 1"
            :balloon="{header: 'header', body: 'body', footer: 'footer'}"
            :icon="markerIcon"
            cluster-name="1")


</template>

<script>
import Button from "@/components/main/Button";
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { mapMutations } from "vuex"

export default {
name: "Contacts",
  components: {Button, yandexMap, ymapMarker},
  coords: [59.914740, 30.277500],
  data() {
    return {
      settings: {
        apiKey: ''
      },
      placemarks: [
        {
          coords: [59.914740, 30.277500],
          properties: {}, // define properties here
          options: {}, // define options here
          clusterName: "1",
          callbacks: { click: function() {} }
        }
      ],
      markerIcon: {
        layout: 'default#imageWithContent',
        // imageHref: 'https://image.flaticon.com/icons/png/512/33/33447.png',
        imageHref: require('@/assets/imgs/map-marker.svg'),
        imageSize: [30, 30],
        imageOffset: [-30, -20],
        contentOffset: [0, 0],
      }
    }
  },
    methods: {
      ...mapMutations(["setModalStatus"])
    },
  mounted() {
    setTimeout(() => {
      const tlVueGsap = new TimelineMax()
          .from('.contacts__info h2 span:first-child', 1, {y: 100, rotation: "8deg", opacity: 0, ease: "Power2.easeOut"})
      const scene4 = this.$scrollmagic
          .scene({
            triggerElement: ".contacts",
            triggerHook: 0.5,
          })
          .setTween(tlVueGsap)

      this.$scrollmagic.addScene(scene4);

    }, 100)
  }
}
</script>

<style scoped>
</style>